import React, { useState, useEffect, useContext } from "react";
import Index from "../Index";
import {
  activeShortCut,
  getAngelListData,
  getWishListData,
  manageCustomScript,
  manageScript,
  pageSetting,
  setGridViewStore,
} from "../../redux/user/action";
import moment from "moment";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RecentIndex = (props) => {
  // Const declaration region start
  const dispatch = Index.useDispatch();

  const marketsList = [
    { id: 1, name: "Equity", type: "NSE" },
    { id: 2, name: "F&O", type: "NFO" },
    { id: 3, name: "Commodity", type: "MCX" },
    { id: 4, name: "WatchList", type: "watchList" },
  ];

  const globalScripts = [
    { id: 1, name: "NIFTY 50", stockType: "NSE", value: 50 },
    { id: 2, name: "NIFTY 100", stockType: "NSE", value: 100 },
    { id: 3, name: "NIFTY 200", stockType: "NSE", value: 200 },
    { id: 4, name: "NIFTY 500", stockType: "NSE", value: 500 },
    { id: 5, name: "Near Month", stockType: "NFO", value: "near" },
    { id: 6, name: "Far Month", stockType: "NFO", value: "far" },
    { id: 7, name: "Near Month", stockType: "MCX", value: "near" },
    { id: 8, name: "Far Month", stockType: "MCX", value: "far" },
  ];

  const futureAndOptionsScript = [
    { id: 1, name: "Near Month", stockType: "NFO", value: "near" },
    { id: 2, name: "Far Month", stockType: "NFO", value: "far" },
  ];

  const CommoditiesScript = [
    { id: 1, name: "Near Month", stockType: "MCX", value: "near" },
    { id: 2, name: "Far Month", stockType: "MCX", value: "far" },
  ];

  const {
    token,
    chartDuration,
    timeFrame,
    wishListData,
    chartView,
    liveData,
    selectedScript,
    gridView,
  } = Index.useSelector((state) => state.UserReducer);
  // Const declaration region end

  // State declaration region start
  const [symbollist, setSymbollist] = useState([]);
  const [indexList, setIndexList] = useState([]);
  const [filterWatchlist, setFilterWatchlist] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [input, setInput] = useState("");
  const [loader, setLoader] = useState(false);
  const [activeScript, setActiveScript] = useState();
  const [watchList, setWatchList] = useState([]);
  const [favoriteWatchList, setFavoriteWatchList] = useState([]);
  const [newListName, setNewListName] = useState("");
  const [stockType, setStockType] = useState("");
  const [selectedMarket, setSelectedMarket] = useState(
    selectedScript?.market || ""
  );

  const [value, setValue] = useState(selectedScript?.market);
  const themeTitle = localStorage.getItem("default-theme");

  // const [scriptData, setScriptData] = useState([]);
  // State declaration region end

  const handleChange = (event, newValue) => {
    setNewListName("");
    setValue(newValue);
  };

  //Set GridView

  const handleSetGridView = () => {
    let gridData =
      Object.entries(gridView)?.length > 0
        ? gridView
        : {
            column: 1,
            row: 1,
          };
    dispatch(setGridViewStore(gridView));
  };
  // Hooks declaration region start
  useEffect(() => {
    // getSymbollistData();
  }, []);
  useEffect(() => {
    // new Promise((resolve) => {
    //   setStockData([]);
    //   resolve();
    // }).then(() => {
    //   Promise.resolve(getWatchlistData()).then((res) =>
    //     res?.map((data, index) => {
    //       setTimeout(() => {
    //         getHistorylist(data);
    //       }, 500 * index);
    //     })
    //   );
    // });
  }, [chartDuration, timeFrame]);
  useEffect(() => {
    dispatch(getAngelListData(stockData));
  }, [stockData]);
  // useEffect(() => {
  //   const scriptDataFilter = [];
  //   scriptData &&
  //     Object.values(scriptData).forEach(function (key, index) {
  //       if (index) {
  //         scriptDataFilter.push(key);
  //       }
  //     });
  //   dispatch(setLiveData(scriptDataFilter));
  // }, [scriptData]);
  // Hooks declaration region end

  // Function declaration region start
  // getWatchlist function declaration start
  const addRemoveFavoriteWatchList = (id, status) => {
    let data = {
      id,
      status,
    };
    return Index.DataService(token)
      .post(Index.Api.user.addRemoveFavoriteScript, data)
      .then((response) => {
        getWatchList();
        getAllFavoriteWatchList();
      })
      .catch((error) => {});
  };

  const getWatchlistData = () => {
    return Index.DataService(token)
      .get(Index.Api.user.getWatchlist)
      .then((response) => {
        setFilterWatchlist(response.data.data);
        dispatch(getWishListData(response.data.data));
        return response.data.data;
      })
      .catch((error) => {});
  };
  // getWatchlist function declaration end
  // getSymbollist function declaration start
  const getSymbollistData = (value) => {
    setLoader(true);
    Index.DataService(token)
      .get(Index.Api.user.getSymbollist + value)
      .then((response) => {
        setLoader(false);
        setSymbollist(response.data.data);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  async function createWatchList(scriptType) {
    setLoader(true);
    Index.DataService(token)
      .post(Index.Api.user.createUserWatchList, {
        watchListName: newListName,
        scriptType,
      })
      .then((response) => {
        setLoader(false);
        setSymbollist(response.data.data);
        setNewListName("");
        getWatchList();
      })
      .catch((error) => {
        setLoader(false);
      });
  }
  // getSymbollist function declaration end
  // addtoWatchlist function declaration start
  const addtoWatchlist = (value) => {
    const formDataAdd = new URLSearchParams();
    formDataAdd.append("stock_name", value?.name);
    formDataAdd.append("tags", "Watch");
    formDataAdd.append("symbol", value?.symbol);
    formDataAdd.append("token", value?.token);
    formDataAdd.append("exch_seg", value?.exch_seg);
    Index.DataService(token)
      .post(Index.Api.user.addWatchlist, formDataAdd)
      .then(async (res) => {
        await getWatchlistData();
      })
      .catch((err) => {});
  };
  // addtoWatchlist function declaration end
  // getHistorylist function declaration start
  const getHistorylist = (value) => {
    const formData = new URLSearchParams();
    formData.append("exchange", value?.exch_seg);
    formData.append("symboltoken", value?.token);
    formData.append("interval", chartDuration[chartView]?.interval);
    formData.append(
      "fromdate",
      moment()
        .subtract(chartDuration[chartView]?.timeFrame, "d")
        .set({ hour: 9, minute: 0 })
        .format("YYYY-MM-DD HH:mm")
    );
    formData.append(
      "todate",
      moment().set({ hour: 3, minute: 30 }).format("YYYY-MM-DD HH:mm")
    );
    Index.DataService(token)
      .post(Index.Api.user.angelGetAllHistory, formData)
      .then(async (res) => {
        if (res.data.data) {
          setStockData((prev) => [
            ...prev,
            {
              tags: value?.tags || "Watch",
              stock_name: value?.stock_name || value?.name,
              token: value?.token,
              stockData: res.data.data,
            },
          ]);
        }
      })
      .catch((err) => {});
  };
  // getHistorylist function declaration end
  // handleSearch function declaration start
  const handleSearch = (e, value) => {
    // if (wishListData?.some((e) => +e.token === +value.token)) {
    //   alert(`${value.name} already in wishlist`);
    //   return;
    // } else {
    //   if (value) {
    //     Promise.resolve(addtoWatchlist(value)).then((res) =>
    //       Promise.resolve(getWatchlistData()).then((res1) => {
    //         getHistorylist(value);
    //       })
    //     );
    //   }
    // }
  };
  // handleSearch function declaration end
  const validJson = (str = "") => {
    try {
      return JSON?.parse(str);
    } catch {
      return 0;
    }
  };
  // removeStock function declaration start
  const removeStock = async (id, stock_name) => {
    const formData = new URLSearchParams();
    formData.append("id", id);
    await Index.DataService(token)
      .post(Index.Api.user.removeWatchlist, formData)
      .then(async (res) => {
        if (res.data.data) {
          await getWatchlistData();
          let arr = await stockData.filter(
            (item, indexData) => item?.stock_name,
            stock_name
          );
          setStockData(arr);
        }
      })
      .catch((err) => {});
  };

  const handlePageSetting = (data) => {
    const rowsPerPage = gridView?.row * gridView?.column;
    const countData = Math.ceil(data?.length / rowsPerPage);
    dispatch(pageSetting({ page: 1, count: countData, rowsPerPage }));
  };

  const hitScript = async (args, data) => {
    props?.setShow(!props?.show);
    dispatch(manageScript({ market: data?.category, script: data?.indexName }));
    dispatch(getWishListData(data.stocks || []));
    handleSetGridView();
    handlePageSetting(data?.stocks);
    // try {
    //   await Index.DataService(token)
    //     .get(Index.Api.user.getNiftyData + "/" + args)
    //     .then((e) => {
    //       dispatch(getWishListData(e.data.data));
    //     });
    //   props?.setShow(!props?.show);
    //   dispatch(manageScript({ market: data?.stockType, script: data?.value }));
    //   handleSetGridView();
    // } catch (error) {}
  };

  const HitWatchListScript = async (watchList) => {
    await Index.DataService(token)
      .get(Index.Api.user.getWatchListScript + `${watchList?._id}`)
      .then((e) => {
        dispatch(getWishListData(e.data.data));
        handlePageSetting(e.data.data);
      });
    props?.setShow(!props?.show);
    dispatch(
      manageScript({
        market: "watchList",
        script: watchList?._id,
        name: watchList?.name,
      })
    );
    handleSetGridView();
  };

  const HitNearFarMonthWatchListScript = async (data, isGlobal) => {
    if (!isGlobal) {
      dispatch(getWishListData(data?.stocks || []));
      props?.setShow(!props?.show);
      dispatch(
        manageScript({ market: data?.category, script: data?.indexName })
      );
      handlePageSetting(data?.stocks);
      handleSetGridView();
    } else {
      try {
        const response = await Index.DataService(token).get(
          Index.Api.user.getNearFarMonthStocks,
          { params: { stockType: data?.stockType, month: data?.value } }
        );
        dispatch(getWishListData(response?.data?.data));
        handlePageSetting(response?.data?.data);
      } catch (error) {}
      props?.setShow(!props?.show);
      dispatch(manageScript({ market: data?.stockType, script: data?.name }));
      handleSetGridView();
    }
  };

  //All watchList
  const getWatchList = async (scriptType) => {
    try {
      const response = await Index.DataService(token).get(
        Index.Api.user.getUserWatchList
        // { params: { scriptType } }
      );
      setWatchList(response?.data?.data);
      dispatch(manageCustomScript(response?.data?.data));
    } catch (error) {}
  };

  const getAllFavoriteWatchList = async () => {
    try {
      const response = await Index.DataService(token).get(
        Index.Api.user.getAllFavoriteWatchlist
      );

      setFavoriteWatchList(response?.data?.data);
    } catch (error) {}
  };

  //Get all index
  const getAllIndex = async () => {
    try {
      const response = await Index.DataService(token).get(
        Index.Api.admin.getAllIndex,
        {
          params: {
            isActive: true,
          },
        }
      );
      setIndexList(response.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAllIndex();
  }, []);

  useEffect(() => {
    if (selectedScript?.market === "watchList") {
      getWatchList();
    }
  }, []);

  useEffect(() => {
    getAllFavoriteWatchList();
  }, []);

  // removeStock function declaration end
  // Function declaration region end
  return (
    <>
      <Index.Box className="body-p">
        <Index.Box className="content-wrapper recent-index-wrapper recent-index-main">
          <Index.Box className="search-box">
            {/* <Index.Box className="form-group">
              <Index.Autocomplete
                fullWidth
                id="fullWidth-symbollist"
                variant="standard"
                className="cus-autocom"
                // clearOnEscape={true}
                options={symbollist}
                onChange={handleSearch}
                loading={loader}
                onInputChange={(e,v)=>{
                let value =Index.trimText(v)
                if(value.length>2){
                  getSymbollistData(value)
                }
                 setInput(value)
                }}
                inputValue={input}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                      return option;
                  }
                  return `${option.name} ${option.formatted_ins_name} ${option.exch_seg}`;
              }}
                
                renderInput={(params) => (
                  <>
                    <Index.TextField {...params} placeholder="Search" />
                    <img
                      className="searchWhite"
                      alt="searchWhite"
                      src={Index.Svg.searchWhite}
                    />
                  </>
                )}
              
              />
            </Index.Box> */}
          </Index.Box>
          <Index.Box className="tab-content-main report-tab-content-main recentindex-tab-main">
            <Index.Box className="index-input-wrapper">
              <Index.Box className="market-list-main">
                {marketsList?.map((name, index) => {
                  return (
                    <>
                      <Index.Box
                        key={`marketsList-${index}`}
                        className={
                          name?.type == selectedMarket
                            ? "index-input-grp input-box active-box"
                            : "index-input-grp input-box"
                        }
                        onClick={() => {
                          setSelectedMarket(name?.type);
                          if (name?.type === "watchList") {
                            getWatchList(name?.type);
                          }
                        }}
                      >
                        <Index.Box className="label-no label-no-custom cus-center">
                          {index + 1}
                        </Index.Box>
                        <Index.Box className="form-group">
                          <Index.Box className="form-control">
                            {name?.name}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </>
                  );
                })}
              </Index.Box>
              <Index.Box className="script-list-main">
                {/* <Index.Box className="index-input-wrapper"> */}
                {/* {globalScripts
                  ?.filter((ele) => ele?.stockType === selectedMarket)
                  .map((name, index) => {
                    return (
                      <>
                        <Index.Box
                          className={
                            name?.value == selectedScript?.script &&
                            name?.stockType == selectedScript?.market
                              ? "index-input-grp input-box active-box"
                              : "index-input-grp input-box"
                          }
                          key={`NIFTY ${name}`}
                        >
                          <Index.Box className="label-no cus-center">
                            {index + 1}
                          </Index.Box>
                          <Index.Box
                            className="form-group"
                            onClick={() => {
                              if (name?.stockType === "NSE") {
                                hitScript(name?.value, name);
                              } else if (
                                name?.stockType === "NFO" ||
                                name?.stockType === "MCX"
                              ) {
                                HitNearFarMonthWatchListScript(name);
                              } else {
                                getWatchList();
                              }
                            }}
                          >
                            <Index.Box className="form-control">
                              {name?.name}
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            className="label-no-favorite cus-center"
                            onClick={() =>
                              addRemoveFavoriteWatchList(
                                name?._id,
                                name?.isFavorite == true ? false : true
                              )
                            }
                          >
                            {name?.isFavorite ? (
                              <Index.StarIcon className="favorite-icon favorite" />
                            ) : (
                              <Index.StarBorderIcon className="favorite-icon" />
                            )}
                          </Index.Box>
                        </Index.Box>
                      </>
                    );
                  })} */}
                {selectedMarket === "NSE" &&
                  indexList
                    ?.filter((ele) => ele?.category === "NSE")
                    .map((item, index) => {
                      return (
                        <>
                          <Index.Box
                            className={
                              item?.indexName == selectedScript?.script &&
                              item?.category == selectedScript?.market
                                ? "index-input-grp input-box active-box"
                                : "index-input-grp input-box"
                            }
                            key={item?._id}
                          >
                            <Index.Box className="label-no label-no-custom cus-center">
                              {index + 1}
                            </Index.Box>
                            <Index.Box
                              className="form-group"
                              onClick={() => {
                                hitScript(item?.value, item);
                              }}
                            >
                              <Index.Box className="form-control">
                                {item?.indexName}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </>
                      );
                    })}

                {selectedMarket === "NFO" &&
                  futureAndOptionsScript.map((item, index) => {
                    return (
                      <>
                        <Index.Box
                          className={
                            item?.name == selectedScript?.script &&
                            item?.stockType == selectedScript?.market
                              ? "index-input-grp input-box active-box"
                              : "index-input-grp input-box"
                          }
                          key={`NFO${index}`}
                        >
                          <Index.Box className="label-no label-no-custom cus-center">
                            {index + 1}
                          </Index.Box>
                          <Index.Box
                            className="form-group"
                            onClick={() => {
                              HitNearFarMonthWatchListScript(item, true);
                            }}
                          >
                            <Index.Box className="form-control">
                              {item?.name}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    );
                  })}
                  
                {selectedMarket === "NFO" &&
                  indexList
                    ?.filter((ele) => ele?.category === "NFO")
                    .map((item, index) => {
                      return (
                        <>
                          <Index.Box
                            className={
                              item?.indexName == selectedScript?.script &&
                              item?.category == selectedScript?.market
                                ? "index-input-grp input-box active-box"
                                : "index-input-grp input-box"
                            }
                            key={item?._id}
                          >
                            <Index.Box className="label-no label-no-custom cus-center">
                              {index + 3}
                            </Index.Box>
                            <Index.Box
                              className="form-group"
                              onClick={() => {
                                HitNearFarMonthWatchListScript(item, false);
                              }}
                            >
                              <Index.Box className="form-control">
                                {item?.indexName}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </>
                      );
                    })}

                {selectedMarket === "MCX" &&
                  CommoditiesScript?.map((item, index) => {
                    return (
                      <>
                        <Index.Box
                          className={
                            item?.name == selectedScript?.script &&
                            item?.stockType == selectedScript?.market
                              ? "index-input-grp input-box active-box"
                              : "index-input-grp input-box"
                          }
                          key={`MCX${index}`}
                        >
                          <Index.Box className="label-no label-no-custom cus-center">
                            {index + 1}
                          </Index.Box>
                          <Index.Box
                            className="form-group"
                            onClick={() => {
                              HitNearFarMonthWatchListScript(item, true);
                            }}
                          >
                            <Index.Box className="form-control">
                              {item?.name}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    );
                  })}

                {selectedMarket === "MCX" &&
                  indexList
                    ?.filter((ele) => ele?.category === "MCX")
                    .map((item, index) => {
                      return (
                        <>
                          <Index.Box
                            className={
                              item?.indexName == selectedScript?.script &&
                              item?.category == selectedScript?.market
                                ? "index-input-grp input-box active-box"
                                : "index-input-grp input-box"
                            }
                            key={item?._id}
                          >
                            <Index.Box className="label-no label-no-custom cus-center">
                              {index + 3}
                            </Index.Box>
                            <Index.Box
                              className="form-group"
                              onClick={() => {
                                HitNearFarMonthWatchListScript(item, false);
                              }}
                            >
                              <Index.Box className="form-control">
                                {item?.indexName}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </>
                      );
                    })}

                {selectedMarket === "watchList" && watchList?.length
                  ? watchList?.map((name, index) => {
                      return (
                        <>
                          <Index.Box
                            className={
                              name?._id == selectedScript?.script
                                ? "index-input-grp input-box active-box"
                                : "index-input-grp input-box"
                            }
                            key={`NIFTY ${name}`}
                          >
                            <Index.Box className="label-no label-no-custom cus-center">
                              {index + 1}
                            </Index.Box>
                            <Index.Box
                              className="form-group watchlist-item-box"
                              onClick={() => {
                                HitWatchListScript(name);
                              }}
                            >
                              <Tooltip
                                title={name?.name.length > 15 ? name?.name : ""}
                              >
                                <Index.Box className="form-control watchlist-item-text">
                                  {name?.name}
                                </Index.Box>
                              </Tooltip>
                            </Index.Box>
                            <Index.Box
                              className="label-no-favorite cus-center"
                              onClick={() =>
                                addRemoveFavoriteWatchList(
                                  name?._id,
                                  name?.isFavorite == true ? false : true
                                )
                              }
                            >
                              {name?.isFavorite ? (
                                <Index.StarIcon className="favorite-icon favorite" />
                              ) : (
                                <Index.StarBorderIcon className="favorite-icon" />
                              )}
                            </Index.Box>
                          </Index.Box>
                        </>
                      );
                    })
                  : null}
                {/* </Index.Box> */}
              </Index.Box>
            </Index.Box>
            {selectedMarket === "watchList" && (
              <Index.Box className="text-field-box">
                {/* <Index.Box className="label-no label-no-custom cus-center add-script-label-no">
                  *
                </Index.Box> */}
                <Index.Box className="form-group">
                  <Index.TextField
                    value={newListName}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (
                        (/^[A-Za-z0-9_-]*$/.test(value) || value === "") &&
                        value?.length < 16
                      ) {
                        setNewListName(e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      dispatch(activeShortCut(true));
                    }}
                    onFocus={(e) => {
                      dispatch(activeShortCut(false));
                    }}
                    className="form-control text-field-recent"
                    placeholder="Enter watchList name"
                  />
                </Index.Box>
                <Index.Box className="btn-bg cus-center modal-close-btn add-sprit-btn-box">
                  <Index.IconButton
                    className="add-sprit-btn"
                    onClick={() => createWatchList("NSE")}
                    disabled={loader}
                  >
                    <img
                      className="add-sprit-icon recent-index-icon"
                      alt="SpritsAdd"
                      src={Index.Svg.roundPlus}
                    />
                  </Index.IconButton>
                </Index.Box>
              </Index.Box>
            )}

            {favoriteWatchList?.length > 0 && (
              <Index.Box className="favorite-watchlist-section">
                <Index.Box className="favorite-heading-main">
                  <Index.Typography className="favorite-heading-text">
                    Favorite
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="favorite-list-main">
                  {favoriteWatchList?.length > 0 &&
                    favoriteWatchList?.map((ele, index) => {
                      return (
                        <Index.Box
                          className="favorite-item-box"
                          key={ele?._id}
                          onClick={() => {
                            HitWatchListScript(ele);
                          }}
                        >
                          <Tooltip
                            title={ele?.name.length > 25 ? ele?.name : ""}
                          >
                            <Index.Box
                              className={`index-input-grp-fav input-box ${
                                ele?._id == selectedScript?.script
                                  ? "fav-input-box-active fav-active-text"
                                  : ""
                              } `}
                            >
                              <Index.Box className="label-no label-no-custom cus-center">
                                {index + 1}
                              </Index.Box>
                              <Index.Box className="form-group">
                                <Index.Box className="form-control">
                                  {ele?.name}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Tooltip>
                        </Index.Box>
                      );
                    })}
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default RecentIndex;
