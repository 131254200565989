import {
  React,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Index from "../../Index";
import OneColumn from "./OneColumn";
import {
  currentPagePlus,
  currentPageMinus,
  manageChartDrawingData,
  setGridViewStore,
  manageEraseDrawing,
  manageAltKeyPressed,
  manageChartInstance,
  manageDrawingButton,
  manageDrawingToolActiveDeactive,
  manageDrawingChartToken,
} from "../../../redux/user/action";
import Footer from "../../../component/DefaultLayout/Footer";
import DrawChartButton from "../../../component/modal/DrawChartButton";
import useKeyboardShortcut from "../../../component/common/keyboard-shortcuts/useKeyboardShortcut";
import { DataService } from "../../../config/DataService";
import usePreviousDateCalculator from "../../../component/common/previous-date-calculator/usePreviousDateCalculator";
import _ from "lodash";
import Constants from "../../../component/common/constants";
import CustomLineChartSingle from "../../../component/custom-chart/customLineChartSingle/CustomLineChartSingle";
import CustomAreaChartSingle from "../../../component/custom-chart/custom-single-charts/CustomAreaChartSingle";
import CustomDashedChartSingle from "../../../component/custom-chart/custom-single-charts/CustomDashedChartSingle";
import CustomCendalStickChartSingle from "../../../component/custom-chart/custom-single-charts/CustomCendalStickChartSingle";

const Dashboard = (props) => {
  const {
    gridSpacing,
    gridBorder,
    currentTag,
    wishListData,
    token,
    chartView,
    liveData,
    gridView,
    pageSetting,
    isActiveShortCut,
    isUserLogin,
    displaySettings,
    isDrawingButtonActive,
    isFlip,
    chartDuration,
    graphType,
    selectedChart,
    twoSideArrowZoom,
    isEraseDrawing,
    wishListDataTemp,
    selectedScript,
    isDrawingToolOpen,
    isModalDrawingButtonActive,
    isModalDrawingToolOpen,
    theme,
  } = Index.useSelector((state) => state.UserReducer);

  const dispatch = Index.useDispatch();
  const location = Index.useLocation();
  const abortControllers = useRef([]);
  const isMounted = useRef(true);
  const [openLineChart, setOpenLineChart] = useState(false);
  const [openAreaChart, setOpenAreaChart] = useState(false);
  const [openCandleChart, setOpenCandleChart] = useState(false);
  const [chartIndex, setChartIndex] = useState(false);
  const [scriptData, setScriptData] = useState({});
  const [wishListDataState, setWishListDataState] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [oldData, setOldData] = useState({});
  const [loader, setLoader] = useState(true);
  const [cursor, setCursor] = useState(-1);
  const [gridV, setGridV] = useState({
    row: 0,
    column: 0,
  });

  const themeTitle = localStorage.getItem("default-theme");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    // bgcolor: "#1E2431",
    bgcolor:
      theme == "Light" ? "#fff" : theme == "Dark" ? "#212121" : "#283142",
    borderRadius: "10px 10px 0 0",
    border: "none",
  };

  const [openDrawingChartModal, setOpenDrawingChartModal] = useState(false);
  const [drawingChartData, setDrawingChartData] = useState(null);
  const [tags, setTags] = useState("");
  const miniChartModalRef = useRef(null);

  const [drawingChart, setDrawingChart] = useState(null);
  const [chartHeight, setChartHeight] = useState(0);
  const [chartLiveData, setChartLiveData] = useState(null);

  const fromDate = usePreviousDateCalculator(
    chartView === "Intraday" ? 0 : chartDuration[chartView]?.timeFrame - 1
  );

  const handleOpenLineChart = (index) => {
    setOpenLineChart(true);
    setChartIndex(index);
  };
  const handleCloseLineChart = () => {
    setOpenLineChart(false);
    setChartIndex();
  };
  const handleOpenAreaChart = (index) => {
    setOpenAreaChart(true);
    setChartIndex(index);
  };
  const handleCloseAreaChart = () => {
    setOpenAreaChart(false);
    setChartIndex();
  };
  const handleOpenCandleChart = (index) => {
    setOpenCandleChart(true);
    setChartIndex(index);
  };
  const handleCloseCandleChart = () => {
    setOpenCandleChart(false);
    setChartIndex();
  };

  // useEffect(()=>{
  //   if(props?.socket && drawingChartData && chartView === "Intraday"){
  //     props?.socket?.on(`token_${JSON.parse(drawingChartData?.token)}`, (data) => {
  //       setChartLiveData({...data, token: JSON.parse(data?.token)});
  //     });
      
  //     return ()=>{
  //       if(chartView === "Positional"){
  //         props?.socket?.off(`token_${JSON.parse(drawingChartData?.token)}`)
  //       }
  //     }
  //   }
  // },[props?.socket, drawingChartData, chartView])

  //Render Data
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    setWishListDataState(wishListData); //Grid load restrict
    // setWishListDataState(filterData);
  }, [wishListData]); //Grid load restrict
  // }, [filterData]);

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setCursor(-1);
      setLoader(false);
    }, 1000);
  }, [wishListData, chartView, currentTag]);

  useEffect(() => {
    setPageData([]);
    const indexOfLastRow = pageSetting?.page * pageSetting?.rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - pageSetting?.rowsPerPage;
    const modifiedRows = wishListData?.slice(indexOfFirstRow, indexOfLastRow);
    setPageData(modifiedRows);
    setTimeout(() => {
      setCursor(-1);
    }, 1000);
  }, [pageSetting, wishListData, chartView, currentTag]);

  useEffect(() => {
    const indexOfLastRow = pageSetting?.page * pageSetting?.rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - pageSetting?.rowsPerPage;
    const modifiedRows = wishListData?.slice(indexOfFirstRow, indexOfLastRow);
    setPageData(modifiedRows);
    setFilterData(wishListData);
    setTimeout(() => {
      setCursor(-1);
      setLoader(false);
    }, 1000);
  }, [wishListData, chartView]);

  //Add to favorite
  const handleAddToFavorite = async (favoriteToken) => {
    const favoriteData = wishListData?.find(
      (ele) => JSON.parse(ele?.token) == JSON.parse(favoriteToken)
    );

    if (favoriteData) {
      try {
        let data = {
          token: favoriteData?.token,
          stock_name: favoriteData?.name,
          tradeType: chartView,
          exchange_type: favoriteData?.exch_seg,
        };
        const response = await Index.DataService(token).post(
          Index.Api.user.aadFavoriteTag,
          data
        );

        // toast.success(response?.data?.message);
      } catch (error) {
        if (error?.response?.data?.status === 409) {
          // toast.success(error?.response?.data?.message);
        }
      }
    }
  };

  //Remove from favorite
  const handleRemoveFromFavorite = async (favoriteToken) => {
    try {
      const response = await Index.DataService(token).post(
        Index.Api.user.removeFavoriteTag,
        { token: favoriteToken }
      );

      // toast.success(response?.data?.message);
    } catch (error) {}
  };

  const handleKeyDown = (e) => {
    // setFilterData((el) => {
    setPageData((el) => {
      setGridV((grid) => {
        if (e.keyCode === 38) {
          setCursor((e) => (e >= gridView?.row ? e - gridView?.row : e));
          // setCursor((e) =>
          //   e - 1 <= 0
          //     ? e
          //     : e >
          //         gridView?.row * gridView?.column * (pageSetting?.page - 1) &&
          //       e >= gridView?.row * gridView?.column * (pageSetting?.page - 1)
          //     ? e - gridView?.row
          //     : e
          // );
        } else if (e.keyCode === 40) {
          setCursor((cursor) =>
            cursor < 0
              ? 0
              : cursor < el.length - 1 && el?.length > cursor + gridView?.row
              ? cursor + gridView?.row
              : cursor
          );
          // setCursor((cursor) =>
          //   cursor < 0
          //     ? gridView?.row * gridView?.column * pageSetting?.page -
          //       gridView?.row * gridView?.column
          //     : cursor <
          //         gridView?.row * gridView?.column * pageSetting?.page - 1 &&
          //       gridView?.row * gridView?.column * pageSetting?.page >
          //         cursor + gridView?.row
          //     ? cursor + gridView?.row
          //     : cursor
          // );
        } else if (e.keyCode === 37) {
          if (cursor >= 0) {
            setCursor((e) => (e - 1 === -1 ? e : e - 1));
            // setCursor((e) =>
            //   e ==
            //   gridView?.row * gridView?.column * pageSetting?.page -
            //     gridView?.row * gridView?.column
            //     ? e
            //     : e - 1
            // );
          } else {
            // dispatch(currentPageMinus());
          }
          // setCursor((cursor)=>cursor-grid.row>=0?cursor-grid.row:cursor)
        } else if (e.keyCode === 39) {
          if (cursor >= 0) {
            setCursor((cursor) =>
              cursor < el.length - 1 && cursor !== null ? cursor + 1 : cursor
            );
            // setCursor((cursor) =>
            //   cursor <
            //     gridView?.row * gridView?.column * pageSetting?.page - 1 &&
            //   cursor !== null
            //     ? cursor + 1
            //     : cursor
            // );
          } else {
            // dispatch(currentPagePlus());
          }
          // setCursor((cursor)=>cursor+grid.row)
        } else if (e.keyCode === 46) {
          // setCursor((cursor) => {
          //   let token = window.document
          //     .getElementById(`${`active-chart-${cursor}`}`)
          //     .getAttribute("data");
          //   dispatch(deleteWishListData(token));
          //   setScriptData((prevScriptData) => {
          //     let obj = { ...prevScriptData };
          //     delete obj[token];
          //     return obj;
          //   });
          //   return cursor;
          // });
        } else if (e.keyCode === 70) {
          //Add to Favorite list -- press(F)
          setCursor((cursor) => {
            let token = window?.document
              ?.getElementById(`${`active-chart-${cursor}`}`)
              ?.getAttribute("data");

            handleAddToFavorite(token);
            return cursor;
          });
        } else if (e.keyCode === 82) {
          //Remove From Favorite list --(Press R)
          setCursor((cursor) => {
            let token = window.document
              ?.getElementById(`${`active-chart-${cursor}`}`)
              ?.getAttribute("data");

            handleRemoveFromFavorite(token);
            return cursor;
          });
        }
        return grid;
      });

      return el;
    });
  };

  useEffect(() => {
    if (isActiveShortCut && isUserLogin) {
      window.addEventListener("keydown", handleKeyDown);
      // window.addEventListener("keyup", upKeyHandler);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        // window.removeEventListener("keyup", upKeyHandler);
      };
    }
  }, [isActiveShortCut, isUserLogin, gridView, cursor, pageSetting?.page]);

  useEffect(() => {
    setGridV(gridView);
  }, [gridView]);

  //Get Annotation
  const getAllAnnotations = useCallback(async () => {
    try {
      const response = await DataService(token).get(
        Index.Api.user.getAnnotations,
        {
          params: {
            chartType: chartView,
            isDashboard: true,
            interval:
              Constants.annotationTimeFrames[
                chartDuration[chartView]?.interval
              ],
          },
        }
      );

      dispatch(manageChartDrawingData(response?.data?.data));
    } catch (error) {}
  }, [chartView, chartDuration]);

  useEffect(() => {
    getAllAnnotations();
  }, [chartView, chartDuration]);

  //Update Annotation
  const handleUpdateAnnotation = async (data) => {
    try {
      const response = await DataService(token, "application/json").post(
        Index.Api.user.addUpdateAnnotation,
        {
          ...data,
          isDashboardAnnotations: true,
          interval:
            Constants.annotationTimeFrames[chartDuration[chartView]?.interval],
        }
      );
      getAllAnnotations();
    } catch (error) {}
  };

  function removeAnnotationOnDeletePress() {
    // get the selected annotation
    if (drawingChart?.chart) {
      let controller = drawingChart?.chart.plot(0).annotations();
      let selectedAnnotation = controller.getSelectedAnnotation();
      if (selectedAnnotation) {
        const updatedAnnotations = controller
          .removeAnnotation(selectedAnnotation)
          .toJson()?.annotationsList;

        let annotationData = {
          token: JSON.parse(drawingChart?.token),
          data: updatedAnnotations,
          chartType: chartView,
          interval:
            Constants.annotationTimeFrames[chartDuration[chartView]?.interval],
        };

        handleUpdateAnnotation(annotationData);
      }
    }
  }

  function handleActiveCursorAction() {
    setCursor(-1);
  }

  const previousDate = usePreviousDateCalculator(1);

  const getChartData = async (rowData, setDrawingChartData) => {
    let live = chartView === "Intraday";
    const intervalData =
      Constants.chartInterval[chartDuration[chartView]?.interval];
    const minutesToLess = intervalData?.value * 60000;

    const formData = {
      tokens: [{ token: rowData?.token, type: rowData?.exch_seg }],
      interval: intervalData?.interval,
      fromDate,
      toDate: Index.moment().format("YYYY-MM-DD"),
      offset: intervalData?.offset,
    };
    const formDataIntraday = {
      tokens: [{ token: rowData?.token, type: rowData?.exch_seg }],
      interval: intervalData?.interval,
      fromDate: Index.moment().format("YYYY-MM-DD"),
      toDate: Index.moment().format("YYYY-MM-DD"),
      offset: intervalData?.offset,
      previousDate,
    };
    try {
      const apiCalls = live
        ? [
            Index.DataService(token).post(
              Index.Api.user.getIntradayData,
              formDataIntraday
            ),
          ]
        : [
            Index.DataService(token).post(
              Index.Api.user.getIntradayData,
              formDataIntraday
            ),
            Index.DataService(token).post(
              Index.Api.user.getAllHistoricalData,
              formData
            ),
          ];
      const [intradayData, historicalData] = await Promise.all(apiCalls);

      const previousDateData = intradayData?.data?.data?.prevCloseData;
      if (previousDateData && previousDateData[rowData?.token]) {
        setDrawingChartData((prev) => ({
          ...prev,
          previousClosePrice: previousDateData[rowData?.token],
        }));
      }

      const combinedData = [
        ...(historicalData?.data?.data || []),
        ...(intradayData?.data?.data?.stockData || []),
      ]?.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        if (!acc[key]) {
          acc[key] = item[key];
        } else {
          acc[key] = [...acc[key], ...item[key]];
        }
        return acc;
      }, {});

      const formattedCombinedData = Object.keys(combinedData)?.map((key) => ({
        [key]: combinedData[key],
      }));

      if (
        formattedCombinedData.length &&
        formattedCombinedData[0]?.[rowData?.token] &&
        formattedCombinedData[0][rowData?.token]?.length
      ) {
        const format = formattedCombinedData[0][rowData?.token].map((el) => {
          const timestamp =
            Index.moment(el[0]).startOf("minute").valueOf() - minutesToLess;
          return [timestamp, +el[1], +el[2], +el[3], +el[4], +el[4]];
        });
        setDrawingChartData((prev) => ({ ...prev, stockData: format }));
        setOpenDrawingChartModal(true);
      }
    } catch (error) {}
  };

  const getCurrentTag = (rowData, setDrawingChartData) => {
    const params = {
      chartView,
      timeFrame: chartDuration[chartView]?.timeFrame,
      interval: chartDuration[chartView]?.interval,
    };
    Index.DataService(token)
      .get(`/user/stocks-single-tag?token=${rowData?.token}`, { params })
      .then(({ data }) => {
        const tag = data?.data?.tag || "";
        setDrawingChartData((prev) => ({ ...prev, tag }));
      });
  };

  const handleDrawingChartModal = () => {
    if (cursor >= 0) {
      const chartList = filterData?.slice(
        pageSetting?.page * pageSetting?.rowsPerPage - pageSetting?.rowsPerPage,
        pageSetting?.page * pageSetting?.rowsPerPage
      );

      const rowData = chartList?.[cursor] || null;

      if (rowData) {
        setDrawingChartData({
          ...rowData,
          interval: chartDuration[chartView]?.interval,
        });
        setTags(rowData?.tag || "");
        getChartData(rowData, setDrawingChartData);
        getCurrentTag(rowData, setDrawingChartData);
        dispatch(manageDrawingChartToken(rowData?.token));
      }
    }
  };

  useEffect(() => {
    if (drawingChartData) {
      setTags(drawingChartData?.tag);
    }
  }, [drawingChartData]);

  const handleDrawingChartModalClose = () => {
    setOpenDrawingChartModal(false);
    setDrawingChartData(null);
    if (isModalDrawingButtonActive) {
      dispatch(manageDrawingButton({ type: "Modal" }));
    }

    if (isModalDrawingToolOpen) {
      dispatch(
        manageDrawingToolActiveDeactive({ type: "Modal", status: false })
      );
    }
  };

  const { handleKeyPress, handleKeyUp } = useKeyboardShortcut({
    removeAnnotationOnDeletePress,
    handleEscapeModalOff: handleActiveCursorAction,
    handleDrawingChartModal,
  });

  //Erase single chart drawing
  function handleEraseDrawing() {
    if (drawingChart) {
      let controller = drawingChart?.chart.plot(0).annotations();
      let clearAnnotation = controller.removeAllAnnotations();
      let dataToRemove = clearAnnotation.toJson()?.annotationsList;
      let annotationData = {
        token: JSON.parse(drawingChart?.token),
        data: dataToRemove,
        chartType: chartView,
        interval:
          Constants.annotationTimeFrames[chartDuration[chartView]?.interval],
      };
      handleUpdateAnnotation(annotationData);
      dispatch(manageEraseDrawing(false));
    }
  }

  useEffect(() => {
    if (isEraseDrawing) {
      handleEraseDrawing();
    }
  }, [isEraseDrawing]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        dispatch(setGridViewStore({ column: 2, row: 1 }));
      } else {
        dispatch(setGridViewStore({ column: 1, row: 1 }));
      }
    };

    if (window.innerWidth < 992) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(
    () => {
      let mainGridHeight = document.getElementsByClassName(
        "dashboard-chart-main"
      )?.[0]?.clientHeight;

      let chartHeaderHeight =
        document.getElementsByClassName("chart-header-main")?.[0]?.clientHeight;

      setChartHeight(
        (mainGridHeight -
          (chartHeaderHeight ?? 0) * gridView?.column -
          (gridSpacing * 8 * (gridView?.column - 1) + 8) -
          0) /
          gridView?.column
      );
    },
    [document.getElementsByClassName("chart-header-main")?.[0]?.clientHeight],
    gridView
  );

  return (
    <>
      <Index.Box className="dashboard-main my-dashboard">
        {isDrawingButtonActive && (
          <DrawChartButton
            newChart={drawingChart && drawingChart?.chart}
            token={drawingChart && drawingChart?.token}
            setNewChart={setDrawingChart}
            interval={
              Constants.annotationTimeFrames[chartDuration[chartView]?.interval]
            }
            isDrawingToolOpen={isDrawingToolOpen}
          />
        )}
        <Index.Box className="dashboard-wrapper">
          <Index.Box
            className={`main-content-sec dashboard-main-content-sec ${
              displaySettings?.UtilityBar == "left"
                ? "main-content-sec-right"
                : ""
            } ${twoSideArrowZoom && "hide-main-content-margin"}`}
          >
            {filterData?.length ? (
              <>
                {chartView === "Intraday" || chartView === "Positional" ? (
                  <Index.Grid
                    container
                    spacing={gridSpacing}
                    className={`chart-main dashboard-chart-main dashboard-chart-mobile ${
                      twoSideArrowZoom && "dashboard-chart-main-zoom"
                    } `}
                  >
                    {filterData?.length
                      ? filterData
                          ?.slice(
                            pageSetting?.page * pageSetting?.rowsPerPage -
                              pageSetting?.rowsPerPage,
                            pageSetting?.page * pageSetting?.rowsPerPage
                          )
                          ?.map((item, index, array) => {
                            return (
                              <OneColumn
                                key={item.token}
                                socket={props.socket}
                                headerData={item}
                                data={item}
                                index={index?.token}
                                loopIndex={index}
                                token={item?.token}
                                marketType={item?.exch_seg}
                                chartStyle={0}
                                handleOpenLineChart={handleOpenLineChart}
                                handleOpenAreaChart={handleOpenAreaChart}
                                handleOpenCandleChart={handleOpenCandleChart}
                                length={pageSetting?.rowsPerPage}
                                live={chartView === "Intraday"}
                                active={index === cursor}
                                selectedId={index === cursor ? index : null}
                                setDrawingChart={setDrawingChart}
                                activeCursor={cursor}
                                fromDate={fromDate}
                                handleUpdateAnnotation={handleUpdateAnnotation}
                                openDrawingChartModal={openDrawingChartModal}
                                drawingChartData={drawingChartData}
                              />
                            );
                          })
                      : ""}
                    {filterData?.length
                      ? Array.from(
                          {
                            length:
                              pageSetting?.rowsPerPage -
                              filterData?.slice(
                                pageSetting?.page * pageSetting?.rowsPerPage -
                                  pageSetting?.rowsPerPage,
                                pageSetting?.page * pageSetting?.rowsPerPage
                              )?.length,
                          },
                          (_, index) => (
                            <Index.Grid item xs={gridView?.column} key={index}>
                              <Index.Box sx={{ height: chartHeight }} />
                            </Index.Grid>
                          )
                        )
                      : ""}
                  </Index.Grid>
                ) : (
                  ""
                )}
              </>
            ) : (
              <Index.Box className="loader-box">No record found</Index.Box>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Footer pageData={wishListData} cursor={cursor} />
      {openDrawingChartModal && (
        <>
          {" "}
          <Index.Box>
            <Index.Modal
              open={openDrawingChartModal}
              onClose={() => {
                handleDrawingChartModalClose();
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              ref={miniChartModalRef}
            >
              <Index.Box sx={style} className="mini-chart-modal-box">
                <Index.Box className="modal-header-sec custom-modal-header">
                  <Index.ChartHeader
                    headerData={drawingChartData}
                    data={drawingChartData}
                    chartData={drawingChartData?.stockData}
                    live={chartView === "Intraday"}
                    tags={tags || ""}
                    setTags={setTags}
                    allTags={Constants.allTags}
                    index={drawingChartData?.token}
                    downloadsType={Constants.downloadsType}
                    singleChart={true}
                    singleChartTag={drawingChartData?.tag}
                    // chartLiveData={chartLiveData?.token == drawingChartData?.token ? chartLiveData : null}
                    fromDate={fromDate}
                    previousClosePrice={drawingChartData?.previousClosePrice}
                    socket={props?.socket}
                    onClose={() => {
                      handleDrawingChartModalClose();
                    }}
                    miniChartModalRef={miniChartModalRef}
                    isDrawingChart={true}
                    drawingChart={drawingChart}
                    setDrawingChart={setDrawingChart}
                    drawingChartData={drawingChartData}
                    setDrawingChartData={setDrawingChartData}
                  />
                </Index.Box>
                <Index.Box className="modal-body-sec">
                  {graphType[theme][chartView] === 0 && (
                    <Index.Box className="chart-body custom-chart-body">
                      <CustomLineChartSingle
                        height={window.innerWidth < 768 ? 300 : 500}
                        width={"100%"}
                        headerData={drawingChartData}
                        liveData={drawingChartData?.stockData}
                        stockData={drawingChartData?.stockData}
                        index={JSON.parse(drawingChartData?.token)}
                        stockToken={JSON.parse(drawingChartData?.token)}
                        // chartLiveData={chartLiveData?.token == drawingChartData?.token ? chartLiveData?.tick : null}
                        live={chartView === "Intraday"}
                        isModalChart={true}
                        isDrawingChart={true}
                        handleUpdateAnnotation={handleUpdateAnnotation}
                        setDrawingChart={setDrawingChart}
                        drawingChartData={drawingChartData}
                      />
                    </Index.Box>
                  )}
                  {graphType[theme][chartView] === 1 && (
                    <Index.Box className="chart-body custom-chart-body">
                      <CustomAreaChartSingle
                        height={window.innerWidth < 768 ? 300 : 500}
                        width={"100%"}
                        headerData={drawingChartData}
                        liveData={drawingChartData?.stockData}
                        stockData={drawingChartData?.stockData}
                        index={JSON.parse(drawingChartData?.token)}
                        stockToken={JSON.parse(drawingChartData?.token)}
                        // chartLiveData={chartLiveData?.token == drawingChartData?.token ? chartLiveData?.tick : null}
                        live={chartView === "Intraday"}
                        isModalChart={true}
                        isDrawingChart={true}
                        handleUpdateAnnotation={handleUpdateAnnotation}
                        setDrawingChart={setDrawingChart}
                        drawingChartData={drawingChartData}
                      />
                    </Index.Box>
                  )}
                  {graphType[theme][chartView] === 2 && (
                    <Index.Box className="chart-body custom-chart-body">
                      <CustomDashedChartSingle
                        height={window.innerWidth < 768 ? 300 : 500}
                        width={"100%"}
                        headerData={drawingChartData}
                        liveData={drawingChartData?.stockData}
                        stockData={drawingChartData?.stockData}
                        index={JSON.parse(drawingChartData?.token)}
                        stockToken={JSON.parse(drawingChartData?.token)}
                        // chartLiveData={chartLiveData?.token == drawingChartData?.token ? chartLiveData?.tick : null}
                        live={chartView === "Intraday"}
                        isModalChart={true}
                        isDrawingChart={true}
                        handleUpdateAnnotation={handleUpdateAnnotation}
                        setDrawingChart={setDrawingChart}
                        drawingChartData={drawingChartData}
                      />
                    </Index.Box>
                  )}
                  {graphType[theme][chartView] === 3 && (
                    <Index.Box className="chart-body custom-chart-body">
                      <CustomCendalStickChartSingle
                        height={window.innerWidth < 768 ? 300 : 500}
                        width={"100%"}
                        headerData={drawingChartData}
                        liveData={drawingChartData?.stockData}
                        stockData={drawingChartData?.stockData}
                        index={JSON.parse(drawingChartData?.token)}
                        stockToken={JSON.parse(drawingChartData?.token)}
                        // chartLiveData={chartLiveData?.token == drawingChartData?.token ? chartLiveData?.tick : null}
                        live={chartView === "Intraday"}
                        isModalChart={true}
                        isDrawingChart={true}
                        handleUpdateAnnotation={handleUpdateAnnotation}
                        setDrawingChart={setDrawingChart}
                        drawingChartData={drawingChartData}
                      />
                    </Index.Box>
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Modal>
          </Index.Box>{" "}
        </>
      )}
    </>
  );
};

export default Dashboard;
