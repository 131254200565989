import { useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import {
//   showHeaderSearchBar,
//   showDefaultSettingModal,
//   GetGraphTypeAction,
//   setChartDuration,
//   setChartView,
//   setTimeFrame,
// } from "./actions"; // Import your action creators here

const useKeyboardShortcut = (externalFunctions = {}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isActiveShortCut,
    isUserLogin,
    chartView,
    timeFrame,
    openDefaultSettingModal,
    chartDuration,
    twoSideArrowZoom,
  } = useSelector((state) => state.UserReducer);

  const pressedKeys = useRef([]);

  const addPressedKey = useCallback((key) => {
    key = key.toLowerCase();
    pressedKeys.current.push(key);
  }, []);

  const handleKeyPress = useCallback(
    (event) => {
      const key = event.key.toLowerCase();
      addPressedKey(key);
    },
    [addPressedKey]
  );

  const handleKeyUp = useCallback(
    (event) => {
      const key = event.key.toLowerCase();
      let keys = pressedKeys.current;
      if (isActiveShortCut && isUserLogin) {
        if (keys.includes("alt") && keys.includes("b")) {
          externalFunctions?.handleShortcutBuyStock &&
            externalFunctions?.handleShortcutBuyStock();
        } else if (keys.includes("alt") && keys.includes("s")) {
          externalFunctions?.handleShortcutSellStock &&
            externalFunctions?.handleShortcutSellStock();
        } else if (keys.includes("alt") && keys.includes("w")) {
          externalFunctions?.handleShortcutWatchStock &&
            externalFunctions?.handleShortcutWatchStock();
        } else if (keys.includes("alt") && keys.includes("r")) {
          if (externalFunctions?.handleShortcutReverseStock) {
            externalFunctions?.handleShortcutReverseStock();
          }
          if (externalFunctions?.handleShortcutRemoveStock) {
            externalFunctions?.handleShortcutRemoveStock();
          }
        } else if (keys.includes("shift") && keys.includes("s")) {
          externalFunctions?.handleHideChartTimeScale &&
            externalFunctions?.handleHideChartTimeScale();
        } else if (keys.includes("shift") && keys.includes("p")) {
          externalFunctions?.handleHideChartPriceRange &&
            externalFunctions?.handleHideChartPriceRange();
        } else if (keys.includes("shift") && keys.includes("d")) {
          externalFunctions?.handleOpenDrawingModal &&
            externalFunctions?.handleOpenDrawingModal();
        } else if (keys.includes("alt") && keys.includes("t")) {
          externalFunctions?.handleSetTarget &&
            externalFunctions?.handleSetTarget();
        } else if (keys.includes("alt") && keys.includes("l")) {
          externalFunctions?.handleSetStopLoss &&
            externalFunctions?.handleSetStopLoss();
        } else if (keys.includes("shift") && keys.includes("e")) {
          if (externalFunctions?.handleShortcutExitStock) {
            externalFunctions?.handleShortcutExitStock &&
              externalFunctions?.handleShortcutExitStock();
          }
          if (externalFunctions?.handleOrderStockExit) {
            externalFunctions?.handleOrderStockExit &&
              externalFunctions?.handleOrderStockExit();
          }
        } else if (keys.includes("shift") && keys.includes("o")) {
          externalFunctions?.handleDrawingChartModal &&
            externalFunctions?.handleDrawingChartModal();
        } else if (keys.includes(" ")) {
          if (!twoSideArrowZoom) {
            externalFunctions?.handleSpacePressed &&
              externalFunctions?.handleSpacePressed();
          }
        } else if (keys.includes("escape")) {
          externalFunctions?.handleEscapeModalOff &&
            externalFunctions?.handleEscapeModalOff();
        } else if (keys.includes("delete")) {
          if (externalFunctions?.headerRemoveTargetOrStopLoss) {
            externalFunctions?.headerRemoveTargetOrStopLoss &&
              externalFunctions?.headerRemoveTargetOrStopLoss();
          }
          if (externalFunctions?.removeAnnotationOnDeletePress) {
            externalFunctions?.removeAnnotationOnDeletePress &&
              externalFunctions?.removeAnnotationOnDeletePress();
          }
        } else if (keys.includes("s")) {
          externalFunctions?.headerSearch && externalFunctions?.headerSearch();
        } else if (keys.includes("l")) {
          externalFunctions?.handleLineChart &&
            externalFunctions?.handleLineChart();
        } else if (keys.includes("a")) {
          externalFunctions?.handleAreaChart &&
            externalFunctions?.handleAreaChart();
        } else if (keys.includes("d")) {
          externalFunctions?.handleDashedChart &&
            externalFunctions?.handleDashedChart();
        } else if (keys.includes("c")) {
          externalFunctions?.handleCandlestickChart &&
            externalFunctions?.handleCandlestickChart();
        } else if (keys.includes("p")) {
          externalFunctions?.positionalViewOpen &&
            externalFunctions?.positionalViewOpen();
        } else if (keys.includes("i")) {
          externalFunctions?.intradayViewOpen &&
            externalFunctions?.intradayViewOpen();
        } else if (keys.includes("e")) {
          externalFunctions?.handleShortcutToggleTwoArrowZoom &&
            externalFunctions?.handleShortcutToggleTwoArrowZoom();
        } else if (keys.includes("alt") && keys.includes("backspace")) {
          if (!twoSideArrowZoom) {
            externalFunctions?.handleBackspacePressed &&
              externalFunctions?.handleBackspacePressed();
          }
        } else if (keys.includes("alt") && keys.includes("1")) {
          if (chartDuration[chartView]?.timeFrame === 5) {
            externalFunctions?.handleFiveMinute &&
              externalFunctions?.handleFiveMinute(
                chartDuration[chartView]?.timeFrame,
                "FIVE_MINUTE"
              );
          } else if (chartDuration[chartView]?.timeFrame === 15) {
            externalFunctions?.handleFifteenMinute &&
              externalFunctions?.handleFifteenMinute(
                chartDuration[chartView]?.timeFrame,
                "FIFTEEN_MINUTE"
              );
          } else if ([30, 90,180, 365]?.includes(chartDuration[chartView]?.timeFrame)) {
            externalFunctions?.handleFourHour &&
              externalFunctions?.handleFourHour(
                chartDuration[chartView]?.timeFrame,
                "FOUR_HOUR"
              );
          } else if (
            chartDuration[chartView]?.timeFrame === 730
          ) {
            externalFunctions?.handleOneWeek &&
              externalFunctions?.handleOneWeek(
                chartDuration[chartView]?.timeFrame,
                "ONE_WEEK"
              );
          }
        } else if (keys.includes("alt") && keys.includes("2")) {
          if (chartDuration[chartView]?.timeFrame === 5) {
            externalFunctions?.handleFifteenMinute &&
              externalFunctions?.handleFifteenMinute(
                chartDuration[chartView]?.timeFrame,
                "FIFTEEN_MINUTE"
              );
          } else if (chartDuration[chartView]?.timeFrame === 15) {
            externalFunctions?.handleThirtyMinute &&
              externalFunctions?.handleThirtyMinute(
                chartDuration[chartView]?.timeFrame,
                "THIRTY_MINUTE"
              );
          } else if (
            [30, 90, 180, 365]?.includes(chartDuration[chartView]?.timeFrame)
          ) {
            externalFunctions?.handleOneDay &&
              externalFunctions?.handleOneDay(
                chartDuration[chartView]?.timeFrame,
                "ONE_DAY"
              );
          }
        } else if (keys.includes("alt") && keys.includes("3")) {
          if (chartDuration[chartView]?.timeFrame === 5) {
            externalFunctions?.handleThirtyMinute &&
              externalFunctions?.handleThirtyMinute(
                chartDuration[chartView]?.timeFrame,
                "THIRTY_MINUTE"
              );
          } else if (chartDuration[chartView]?.timeFrame === 15) {
            externalFunctions?.handleOneHour &&
              externalFunctions?.handleOneHour(
                chartDuration[chartView]?.timeFrame,
                "ONE_HOUR"
              );
          } else if (
            [180, 365]?.includes(chartDuration[chartView]?.timeFrame)
          ) {
            externalFunctions?.handleOneWeek &&
              externalFunctions?.handleOneWeek(
                chartDuration[chartView]?.timeFrame,
                "ONE_WEEK"
              );
          }
        } else if (keys.includes("alt") && keys.includes("4")) {
          if ([5, 15]?.includes(chartDuration[chartView]?.timeFrame)) {
            externalFunctions?.handleFourHour &&
              externalFunctions?.handleFourHour(
                chartDuration[chartView]?.timeFrame,
                "FOUR_HOUR"
              );
          }
        } else if (keys.includes("1")) {
          if (chartView === "Intraday") {
            externalFunctions?.handleOneMinInterval &&
              externalFunctions?.handleOneMinInterval();
          } else {
            externalFunctions?.handleFiveWorkingDay &&
              externalFunctions?.handleFiveWorkingDay();
          }
        } else if (keys.includes("2")) {
          if (chartView === "Intraday") {
            externalFunctions?.handleFiveMinInterval &&
              externalFunctions?.handleFiveMinInterval();
          } else {
            externalFunctions?.handleFifteenWorkingDay &&
              externalFunctions?.handleFifteenWorkingDay();
          }
        } else if (keys.includes("3")) {
          if (chartView === "Intraday") {
            externalFunctions?.handleTenMinInterval &&
              externalFunctions?.handleTenMinInterval();
          } else {
            externalFunctions?.handleOneMonthChart &&
              externalFunctions?.handleOneMonthChart();
          }
        } else if (keys.includes("4")) {
          if (chartView === "Intraday") {
            externalFunctions?.handleFifteenMinInterval &&
              externalFunctions?.handleFifteenMinInterval();
          } else {
            externalFunctions?.handleThreeMonthChart &&
              externalFunctions?.handleThreeMonthChart();
          }
        } else if (keys.includes("5")) {
          if (chartView === "Intraday") {
            externalFunctions?.handleThirtyMinInterval &&
              externalFunctions?.handleThirtyMinInterval();
          } else {
            externalFunctions?.handleSixMonthChart &&
              externalFunctions?.handleSixMonthChart();
          }
        } else if (keys.includes("6")) {
          if (chartView === "Positional") {
            externalFunctions?.handleOneYearChart &&
              externalFunctions?.handleOneYearChart();
          }
        } else if (keys.includes("7")) {
          if (chartView === "Positional") {
            externalFunctions?.handleTwoYearChart &&
              externalFunctions?.handleTwoYearChart();
          }
        } 
        // else if (keys.includes("8")) {
        //   if (chartView === "Positional") {
        //     externalFunctions?.handleFiveYearChart &&
        //       externalFunctions?.handleFiveYearChart();
        //   }
        // }
        pressedKeys.current = [];
        keys = [];
      } else {
        pressedKeys.current = [];
        keys = [];
      }
    },
    [
      isActiveShortCut,
      isUserLogin,
      dispatch,
      navigate,
      externalFunctions,
      addPressedKey,
      twoSideArrowZoom,
    ]
  );

  useEffect(() => {
    if (isActiveShortCut && isUserLogin) {
      document.addEventListener("keyup", handleKeyUp);
      return () => {
        document.removeEventListener("keyup", handleKeyUp);
      };
    }
  }, [isActiveShortCut, isUserLogin, handleKeyUp]);

  useEffect(() => {
    if (isActiveShortCut && isUserLogin) {
      document.addEventListener("keydown", handleKeyPress);
      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [isActiveShortCut, isUserLogin, handleKeyPress]);

  return { handleKeyPress, handleKeyUp };
};

export default useKeyboardShortcut;
