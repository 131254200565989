import React from "react";
import Index from "../Index";
import { useEffect } from "react";
import { useState } from "react";
import {
  currentPageMinus,
  currentPagePlus,
  manageCurrentPage,
  pageSetting,
} from "../../redux/user/action";
import UserReducer from "../../redux/user/reducer";

const Footer = (props) => {
  const dispatch = Index.useDispatch();
  const {
    wishListData,
    gridView,
    currentPageRedux,
    chartViewChanged,
    chartView,
    isActiveShortCut,
    isUserLogin
  } = Index.useSelector((state) => state.UserReducer);
  const [page, setPage] = useState(currentPageRedux);
  const [count, setCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    if (props?.apiHasBeenCalled) {
      props.apiHasBeenCalled.current = null;
    }
    setPage(newPage);
    dispatch(manageCurrentPage(newPage));
  };

  const handleInputChange = (event) => {
    const inputPage = parseInt(event.target.value, 10);
    setPage(inputPage);
    dispatch(manageCurrentPage(inputPage));
  };

  const handleInputBlur = () => {
    // Validate and update page state if needed
    const pageNumber = Math.min(Math.max(page, 1), count);
    setPage(pageNumber);
    dispatch(manageCurrentPage(pageNumber));
  };

  const rowsPerPage = gridView?.row * gridView?.column;
  useEffect(() => {
    //Page count
    const countData = Math.ceil(props?.pageData?.length / rowsPerPage);
    setCount(countData);
    dispatch(
      pageSetting({ page: currentPageRedux, count: countData, rowsPerPage })
    );
  }, [page, props?.pageData, gridView, currentPageRedux]);

  useEffect(() => {
    setPage(currentPageRedux);
  }, [currentPageRedux]);

  // useEffect(() => {
  //   setPage(1);
  // }, [chartViewChanged]);

  useEffect(() => {
    dispatch(manageCurrentPage(1));
  }, []);

  const handleKeysUp = (event) => {
    if (props?.cursor < 0) {
      if (event?.keyCode === 37) {
        dispatch(currentPageMinus());
      }
      if (event?.keyCode === 39) {
        dispatch(currentPagePlus());
      }
    }
  };

  useEffect(() => {
    if (isActiveShortCut && isUserLogin) {
      window.addEventListener("keyup", handleKeysUp);
      return () => {
        window.removeEventListener("keyup", handleKeysUp);
      };
    }
  }, [isActiveShortCut, isUserLogin, gridView, props?.cursor, pageSetting?.page]);

  if (isNaN(count) || count <= 1) {
    return <></>;
  }

  return (
    <>
      <Index.Box className="footer-sec">
        <Index.Box className="flex footer-pagination-box">
          <Index.Pagination
            count={count}
            page={page}
            onChange={handleChangePage}
            shape="rounded"
            hidePrevButton
            hideNextButton
            className="pagination-btn-box"
          />
          <Index.TextField
            size="small"
            className="index-input-grp input-box active-box pagination-input"
            value={page}
            type="number"
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            inputProps={{
              max: count,
              min: 1,
            }}
          />
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Footer;
