import React, { memo, useEffect, useReducer, useState } from "react";
import Index from "../Index";
import { useDispatch, useSelector } from "react-redux";
import {
  sortWishListData,
  manageCurrentTag,
  getWishListData,
  manageSortingTags,
  manageSorting,
  manageUserSetting,
} from "../../redux/user/action";
import { getUserAllTagsList } from "../../service/UserIndex";
import { Tooltip } from "@mui/material";
import { Dashboard } from "@mui/icons-material";
import Constants from "../common/constants";

const sortType = ["Buy", "Sell", "Watch"];

const SortingModal = (props) => {
  const dispatch = useDispatch();
  const location = Index.useLocation();
  // const [isDataWithSorted, setIsDataWithSorted] = useState(true);

  const { token, currentTag, sorting } = useSelector(
    (state) => state.UserReducer
  );

  const pathName = Constants.sortingPath[location?.pathname];
  const isTagSortRestrict = !Constants.defaultSorting[pathName]?.isTagsSort;
  const isTimeFrameSortRestrict =
    !Constants.defaultSorting[pathName]?.isTimeFrameSort;

  const handleManageSettings = (value) =>{
    dispatch(manageUserSetting(token, `sorting.${pathName}`, value));
  } 

  function filterDataBySequence(data, sequence) {
    let filteredData = [];
    if (sequence?.length) {
      sequence?.forEach((ele, index) => {
        data?.forEach((item, i) => {
          if (item?.tag?.toLowerCase() === ele?.toLowerCase()) {
            filteredData.push(item);
          }
        });
      });
      // for (let i = 0; i < sequence?.length; i++) {
      //   for (let j = 0; j < data.length; j++) {
      //     if (data[j]?.tag?.toLowerCase() === sequence[i]?.toLowerCase()) {
      //       filteredData.push(data[j]);
      //     }
      //   }
      // }
    } else {
      filteredData = data;
    }
    filteredData = !sorting[pathName]?.order
      ? filteredData?.sort((a, b) => {
          const aName = a?.name || a?.stock_name;
          const bName = b?.name || b?.stock_name;
          return aName.localeCompare(bName);
        })
      : filteredData?.sort((a, b) => {
          const aName = a?.name || a?.stock_name;
          const bName = b?.name || b?.stock_name;
          return bName.localeCompare(aName);
        });
    return filteredData;
  }

  const filterFromOptions = async (tag) => {
    let data = await getUserAllTagsList(token);

    const filterTag = filterDataBySequence(
      data?.data,
      sorting["Dashboard"]?.tags
    );

    props?.setSortedDataOption &&
      props?.setSortedDataOption((prev) => ({
        ...prev,
        ["tag"]: sorting["Dashboard"]?.tags,
      }));
    dispatch(manageCurrentTag({ tag: sorting["Dashboard"]?.tags, filterTag }));
    // dispatch()
    // props?.setShow(!props?.show);
  };

  const handleAscDescFilter = () => {
    const isSorting =
      sorting[pathName]?.order === null ? 1 : sorting[pathName]?.order == 1 ? -1 : 1;
    props?.setSortedDataOption &&
      props?.setSortedDataOption((prev) => ({
        ...prev,
        ["isAsc"]: !props?.sortedDataOption?.isAsc,
      }));

    // dispatch(sortWishListData(!isSortedWishlist));
    dispatch(
      manageSorting({
        pathName,
        data: {
          order: isSorting,
          timeFrame: 0,
          reset: 0,
        },
      })
    );
    const payload = {
      ...Constants.defaultSorting[pathName],
      order: isSorting,
      timeFrame: 0,
      reset: 0,
      tags: sorting[pathName]?.tags,
    };
    handleManageSettings(payload)
  };

  const handleManageSortingTag = (tag) => {
    let tags = sorting[pathName]?.tags;
    const ifExist = tags?.some((item) => item === tag);

    if (ifExist) {
      tags = tags?.filter((item) => item !== tag);
    } else {
      tags = [...tags, tag];
    }
    dispatch(manageSorting({ pathName, data: { tags, timeFrame: 0, reset: 0 } }));
    const payload = {
      ...Constants.defaultSorting[pathName],
      order: sorting[pathName]?.order,
      tags,
      timeFrame: 0,
      reset: 0
    };
    handleManageSettings(payload)
  };

  const resetFilter = async () => {
    dispatch(manageCurrentTag({ tag: "" }));
    dispatch(manageSortingTags(""));

    props?.setSortedDataOption &&
      props?.setSortedDataOption({
        isAsc: true,
        tage: "",
      });
    dispatch(sortWishListData("reset"));
    dispatch(
      manageSorting({ pathName, data: Constants.defaultSorting[pathName] })
    );

    const payload = {
      ...Constants.defaultSorting[pathName],
    };
    handleManageSettings(payload);
  };

  //Handle Time Frame Sort
  const handleTimeFrameSort = () => {
    dispatch(
      manageSorting({
        pathName,
        data: {
          order: 0,
          tags: [],
          timeFrame:
            sorting[pathName]?.timeFrame === 0
              ? 1
              : sorting[pathName]?.timeFrame == 1
              ? -1
              : 1,
          reset: 0,
        },
      })
    );

    const payload = {
      ...Constants.defaultSorting[pathName],
      order: 0,
      tags: [],
      timeFrame:
        sorting[pathName]?.timeFrame === 0
          ? 1
          : sorting[pathName]?.timeFrame == 1
          ? -1
          : 1,
      reset: 0,
    };
    handleManageSettings(payload);
  };

  useEffect(() => {
    if (!!sorting["Dashboard"]?.tags?.length) {
      filterFromOptions();
    } else {
      dispatch(manageCurrentTag({ tag: "" }));
    }
  }, [sorting["Dashboard"]?.tags]);

  useEffect(() => {
    getUserAllTagsList(token);
  }, []);

  return (
    <>
      <Index.Box className="body-p sorting-body-wrapper">
        <Index.Box className="content-wrapper">
          <Index.Box className="filter-btn-main" disableRipple>
            <Tooltip title="Reset">
              <Index.Button
                onClick={resetFilter}
                className={`filter-btn cus-center reset-btn-bg ${sorting[pathName]?.reset  && 'active'}`}
              >
                {" "}
                <img
                  className="reload-img"
                  src={Index.Svg.filterIc}
                  alt="filterIc"
                />
              </Index.Button>
            </Tooltip>
          </Index.Box>
          <Index.Box className="name-wrap">
            <Index.Typography className="cus-lablel" variant="p" component="p">
              Name
            </Index.Typography>
            <Index.Box
              className={`btn-bg cus-center ${
                sorting[pathName]?.order !== 0 ? "active" : "order-sort-bg"
              }`}
              onClick={handleAscDescFilter}
            >
              <img
                className="head-btn-img sort-icon"
                alt="zTOa"
                src={
                  (sorting[pathName]?.order == 1 || !sorting[pathName]?.order) ? Index.Svg.aToz : Index.Svg.zTOa
                }
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="name-wrap">
            <Index.Box className="sorting-label-flex">
              <Index.Typography
                className="cus-lablel"
                variant="p"
                component="p"
              >
                Tag
              </Index.Typography>
              {isTagSortRestrict && (
                <Index.Typography
                  className="sorting-restrict-msg"
                  variant="p"
                  component="p"
                >
                  Tag sorting restricted.
                </Index.Typography>
              )}
            </Index.Box>
            <Index.Box className="sorting-tag-sec">
              {sortType.map((e, i) => {
                return (
                  <>
                    <Index.Box
                      className={`tag-main ${
                        sorting[pathName]?.tags?.includes(e)
                          ? "sorting-tag-active"
                          : ""
                      }`}
                      onClick={() => {
                        if (!isTagSortRestrict) {
                          dispatch(manageSortingTags(e));
                          // filterFromOptions();
                          handleManageSortingTag(e);
                        }
                      }}
                      sx={{
                        cursor: isTagSortRestrict ? "not-allowed" : "pointer",
                      }}
                    >
                      <Index.Box
                        className={`btn-bg cus-center ${
                          e === "Watch" ? "last-child" : ""
                        } || ${
                          sorting[pathName]?.tags?.includes(e)
                            ? "sort-sr-btn-active"
                            : ""
                        }`}
                        sx={{
                          cursor: isTagSortRestrict ? "not-allowed" : "pointer",
                        }}
                      >
                        {i + 1}
                      </Index.Box>
                      <Index.Typography
                        className={`tag-label ${
                          sorting[pathName]?.tags?.includes(e)
                            ? "sort-active-label"
                            : ""
                        }`}
                        variant="p"
                        component="p"
                      >
                        {e}
                      </Index.Typography>
                    </Index.Box>
                  </>
                );
              })}
            </Index.Box>
          </Index.Box>
          <Index.Box className="name-wrap">
            <Index.Box className="sorting-label-flex">
              <Index.Typography
                className="cus-lablel"
                variant="p"
                component="p"
              >
                Time Frame
              </Index.Typography>
              {isTimeFrameSortRestrict && (
                <Index.Typography
                  className="sorting-restrict-msg"
                  variant="p"
                  component="p"
                >
                  Time Frame sorting restricted.
                </Index.Typography>
              )}
            </Index.Box>
            <Index.Box className={`sorting-tag-sec sorting-time-frame ${sorting[pathName]?.timeFrame == -1 && 'flex-reverse' }`}>
              <Index.Box className="tag-main">
                <Index.Typography
                  className="tag-label"
                  variant="p"
                  component="p"
                >
                  Minimum
                </Index.Typography>
              </Index.Box>
              <Index.Box
                className={`arrow-round-sec cus-center ${sorting[pathName]?.timeFrame && 'active'}`}
                onClick={() => {
                  if (!isTimeFrameSortRestrict) {
                    handleTimeFrameSort();
                  }
                }}
                sx={{
                  cursor: isTimeFrameSortRestrict ? "not-allowed" : "pointer",
                }}
              >
                {/* <img
                  className="arrow-round-img right-arrow-img"
                  src={Index.Svg.rightRoundArrow}
                  alt="rightRoundArrow"
                  style={{
                    display:
                      sorting[pathName]?.timeFrame ||
                      sorting[pathName]?.timeFrame === null
                        ? "none"
                        : "block",
                  }}
                />
                <img
                  // className="arrow-round-img left-arrow-img"
                  className="arrow-round-img"
                  src={Index.Svg.leftRoundArrow}
                  alt="leftRoundArrow"
                  style={{
                    display:
                      sorting[pathName]?.timeFrame ||
                      sorting[pathName]?.timeFrame !== null
                        ? "block"
                        : "none",
                  }}
                /> */}
                  <img
                    className="arrow-round-img right-arrow-img"
                    src={Index.Svg.rightRoundArrow}
                    alt="rightRoundArrow"
                  />
              </Index.Box>
              <Index.Box className="tag-main">
                <Index.Typography
                  className="tag-label"
                  variant="p"
                  component="p"
                >
                  Maximum
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default memo(SortingModal);
