import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from "./reportWebVitals";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

{
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const persistStore = Store();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={persistStore.store}>
    {/* <PersistGate persistor={persistStore.persistor}> */}
    <App />

    {/* </PersistGate> */}
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
