import Index from "../../../Index";
import { React, useCallback, useEffect, useMemo, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Subscribe_stock_tokens,
  useSocketData,
  getPrviousDataOfStock,
  useStockDataListener,
} from "../../../../socket";
import { useDispatch, useSelector } from "react-redux";
import CustomLineChartSingle from "../../../../component/custom-chart/customLineChartSingle/CustomLineChartSingle";
import { ContactsOutlined } from "@mui/icons-material";
import CustomMiniChart from "../../../../component/custom-chart/mini-chart/CustomMiniChart";
import { useNavigate } from "react-router-dom";
import { activeShortCut, getWishListData } from "../../../../redux/user/action";
import CustomMiniHistoricalChart from "../../../../component/custom-chart/mini-chart/CustomMiniHistoricalChart";

const setDatefun = (data) => {
  let d = new Date(data);
  let h = d.getHours() + 5;
  let m = d.getMinutes() + 30;
  d.setHours(h, m, 0, 0);
  return d.getTime();
};

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 1000,
//   // bgcolor: "#1E2431",
//   bgcolor: "#283142",
//   borderRadius: "10px 10px 0 0",
//   border: "none",
//   // bgcolor: "background.paper",
//   // border: "2px solid #000",
//   // boxShadow: 24,
//   // p: 4,
// };

const tags = [
  { id: 1, name: "All", value: "All" },
  { id: 2, name: "Open", value: "Active" },
  { id: 3, name: "Exitted", value: "Inactive" },
  { id: 4, name: "Target Hit", value: "Target Hit" },
  { id: 5, name: "Stop Loss Hit", value: "Stop Loss Hit" },
];

const tradeSummaryTags = [
  { id: 1, name: "Performance Report", value: "performanceReport" },
  { id: 2, name: "Trade Report", value: "tradeReport" },
];

const allStockTypes = [
  { id: 1, name: "All", value: "All" },
  { id: 2, name: "Equity", value: "NSE" },
  { id: 3, name: "F&O", value: "MCX" },
];

const Report = () => {
  const allTags = ["Exit", "Watch", "Buy", "Sell", "Reverse"];
  const themeTitle = localStorage.getItem("default-theme");
  let stock_data = useStockDataListener();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { socketData, addSocketData } = useSocketData();
  const [allTagsData, setAllTagsData] = useState([]);
  const {
    wishListData,
    token,
    selectedScript,
    timeFrame,
    chartView,
    chartDuration,
    displaySettings,
    miniChartSize,
    rowsPerPage,
    twoSideArrowZoom,
    theme,
  } = useSelector((state) => state.UserReducer);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    // bgcolor: "#1E2431",
    bgcolor:
      theme == "Light"
        ? "#fff"
        : theme == "Dark"
        ? "#212121"
        : "#283142",
    borderRadius: "10px 10px 0 0",
    border: "none",
    // bgcolor: "background.paper",
    // border: "2px solid #000",
    // boxShadow: 24,
    // p: 4,
  };
  const [isActive, SetIsActive] = useState(true);
  const [active, Setactive] = useState(false);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [chartData, setChartData] = useState();
  const handleOpen = (data) => {
    setTag("buy");
    setminiChartOpen(true);
    // getHistorylist(data);
    setChartData(data?.stockData);
  };
  const [scriptData, setScriptData] = useState();
  const [activeMiniChartOpen, setActiveMiniChartOpen] = useState();
  const [openModel, setOpenModel] = useState(false);
  const [currenChart, setCurrenChart] = useState("");
  const [selectedTag, setSelectedTag] = useState("All");
  const [filterdData, setFilteredData] = useState([]);
  const [stockType, setStockType] = useState("All");
  const [tradeSummaryTag, setTradeSummaryTag] = useState("performanceReport");
  const [tag, setTag] = useState("watch");
  const [miniChartOpen, setminiChartOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: rowsPerPage,
  });

  //Date Range
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [showCalendar, setShowCalendar] = useState(false);
  const changeShowCalendar = () => {
    setShowCalendar(!showCalendar);
  };
  const handleDateSelect = (range) => {
    setDateRange({
      startDate: range?.selection?.startDate,
      endDate: range?.selection?.endDate,
      key: "selection",
    });
    if (range?.selection?.endDate) {
      setShowCalendar(!showCalendar);
    }
  };

  // const handleOpen = () => setOpenModel(true);
  const handleClose = () => {
    setminiChartOpen(false);
    setChartData([]);
  };
  // const handleClose = () => setOpen(false);

  // const { index } = props;
  // const [LineChart, setLineChart] = useState(null);

  // const [open ,  setOpen] = useState(false);

  const userLoginToken = Index.useSelector((state) => state.UserReducer.token);

  useEffect(() => {
    Index.DataService(userLoginToken)
      .get(`${Index.Api.user.getAllTags}?open=true`)
      .then((data) => {
        setAllTagsData(data?.data?.data);
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
      });
  }, []);

  const handleMenu = (e) => {};

  const columns = [
    { field: "sNo", headerName: "Sr.", width: 76, headerAlign: "center" },
    {
      field: "script",
      headerName: "Script",
      width: 200,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "tag",
      headerName: "Tag",
      width: 120,
      editable: false,
      headerAlign: "center",

      renderCell: (params) => (
        <Index.Button
          className={
            params.value.toLowerCase() === "buy"
              ? "green-buy-btn"
              : params.value.toLowerCase() === "sell"
              ? "red-buy-btn"
              : params.value.toLowerCase() === "watch"
              ? "blue-watch-btn"
              : "green-buy-btn"
          }
          disableRipple
        >
          {params.value}
        </Index.Button>
      ),
    },
    {
      field: "CMP",
      headerName: "CMP",
      headerAlign: "center",

      type: "number",
      width: 150,
      editable: false,
    },
    {
      field: "miniChart",
      headerName: "Mini Chart",
      type: "number",
      width: miniChartSize?.width + 50,
      headerAlign: "center",

      editable: false,
      headerAlign: "left",
      renderCell: (params) => (
        <Index.Button
          onClick={() => {
            handleOpen(params?.row);
            setActiveMiniChartOpen(params?.value?.token);
          }}
          disableRipple
        >
          {/* <CustomLineChartSingle
              height={65}
              width={200}
              liveData={socketData}
              index={`1_${params?.value?.token}`}
              stockToken={params?.value?.token}
            /> */}

          <CustomMiniHistoricalChart
            chartData={params?.value?.stockData || []}
            // chartData={socketData}
            token={JSON.parse(params?.value?.token)}
            index={params?.value?.index}
          />
          {/* <CustomMiniChart
            chartData={socketData}
            token={JSON.parse(params?.value?.token)}
          /> */}
        </Index.Button>
      ),
      align: "left",
    },

    {
      field: "stopLossat",
      headerName: "Stop Loss",
      type: "number",
      width: 130,
      editable: false,
      padding: 0,
    },
    {
      field: "targetat",
      headerName: "Target",
      type: "number",
      width: 130,
      editable: false,
      padding: 0,
    },
    {
      field: "updatedAt",
      headerName: "Time",
      type: "number",
      width: 230,
      editable: false,
      padding: 0,
    },
  ];

  useEffect(() => {
    setScriptData(null);
    const tokenList = wishListData?.map((token) => {
      return token?.token || 0;
    });
    Subscribe_stock_tokens(tokenList);
  }, [wishListData]);

  const row = useMemo(() => {
    return Object.values(scriptData || {})?.map((item, index) => {
      const lastTradePrice =
        item?.stockData?.[0] &&
        item?.stockData?.[item?.stockData?.length - 1][2]?.toFixed(2);

      const closePrice =
        item?.stockData?.[0] &&
        item?.stockData?.[item?.stockData?.length - 1][4]?.toFixed(2);
      const changePointPercent =
        ((lastTradePrice - closePrice) / closePrice) * 100;
      const changePointValue = lastTradePrice - closePrice;
      const script = wishListData?.filter(
        (data) => data?.token == JSON.parse(item?.token)
      )?.[0]?.name;

      // const tag = getCurrentTag(item?.token)
      const tagData = scriptData?.[item?.token]?.findToken;

      return {
        index: index,
        tag: item?.tag || "Watch",
        status: item?.status || "Watch",
        CMP: lastTradePrice,
        entry: tagData?.entryPrice || "-",
        stopLoss: tagData?.topLossAt?.price || "-",
        target: tagData?.targetAt?.price || "-",
        chargepoint: `${
          closePrice > lastTradePrice
            ? changePointValue.toFixed(2)
            : closePrice !== lastTradePrice
            ? `+${changePointValue.toFixed(2)}`
            : 0
        } (${changePointPercent ? `${changePointPercent.toFixed(2)}%` : "0%"})`,
        script: item?.stock_name,
        id: index + 1,
        miniChart: {
          token: item?.token,
          lastTradePrice: lastTradePrice,
        },
        createdAt: Index.moment(item?.createdAt).format("DD-MM-YYYY HH:MM:SS"),
        updatedAt: Index.moment(item?.updatedAt).format("DD-MM-YYYY HH:MM:SS"),
        token: item?.token,
        target: item?.targetAt,
        stopLoss: item?.topLossAt,
        targetat: item?.targetAt?.price,
        stopLossat: item?.topLossAt?.price,
        exchange_type: item?.exchange_type,
        stockData: item?.stockData,
        action: [
          {
            token: item?.token,
            value: Index.Svg.roundPlus,
          },
          {
            token: item?.token,
            stockName: item?.name,
            value: Index.Svg.whiteDots,
          },
        ],
      };
    });
  }, [scriptData]);

  useEffect(() => {
    const filteredData = row
      ?.filter((ele) => {
        if (tradeSummaryTag === "performanceReport") {
          return ele;
        } else if (tradeSummaryTag === "tradeReport") {
          return ele?.stopLoss || ele?.target;
        }
      })
      ?.filter((ele) => {
        const exchangeType = ele?.exchange_type?.toString()?.toLowerCase();
        const status = ele?.status?.toString()?.toLowerCase();
        const targetCompleted = ele?.target?.completed === true;
        const stopLossCompleted = ele?.stopLoss?.completed === true;
        const updatedAt = Index.moment(ele?.updatedAt, "DD-MM-YYYY");
        const stopLoss = ele?.stopLoss;
        const target = ele?.target;

        if (stockType !== "All" && selectedTag === "All") {
          return (
            exchangeType === stockType.toLowerCase() && isDateInRange(updatedAt)
          );
        } else if (stockType === "All" && selectedTag !== "All") {
          return selectedTag === "Target Hit"
            ? targetCompleted
            : selectedTag === "Stop Loss Hit"
            ? stopLossCompleted
            : status === selectedTag.toLowerCase() && isDateInRange(updatedAt);
        } else if (stockType === "All" && selectedTag === "All") {
          return isDateInRange(updatedAt);
        } else if (stockType && selectedTag && selectedTag !== "All") {
          return (
            exchangeType === stockType.toLowerCase() &&
            status === selectedTag.toLowerCase() &&
            isDateInRange(updatedAt)
          );
        } else if (stockType && selectedTag && selectedTag === "Target Hit") {
          return (
            exchangeType === stockType.toLowerCase() &&
            targetCompleted &&
            isDateInRange(updatedAt)
          );
        } else if (
          stockType &&
          selectedTag &&
          selectedTag === "Stop Loss Hit"
        ) {
          return (
            exchangeType === stockType.toLowerCase() &&
            stopLossCompleted &&
            isDateInRange(updatedAt)
          );
        } else if (selectedTag === "Target Hit") {
          return targetCompleted && isDateInRange(updatedAt);
        } else if (selectedTag === "Stop Loss Hit") {
          return stopLossCompleted && isDateInRange(updatedAt);
        } else if (stockType && stockType !== "All") {
          return (
            exchangeType === stockType.toLowerCase() && isDateInRange(updatedAt)
          );
        } else if (selectedTag && selectedTag !== "All") {
          return (
            status === selectedTag.toLowerCase() && isDateInRange(updatedAt)
          );
        } else {
          return isDateInRange(updatedAt) && !target && !stopLoss;
        }
      });

    setFilteredData(
      (filteredData &&
        filteredData?.map((ele, index) => {
          return {
            ...ele,
            sNo: index + 1,
          };
        })) ||
        []
    );
  }, [row, stockType, selectedTag, dateRange, tradeSummaryTag]);

  function isDateInRange(date) {
    const startDate = Index.moment(dateRange?.startDate);
    const endDate = Index.moment(dateRange?.endDate);
    return (
      date?.isSameOrAfter(startDate, "day") &&
      date?.isSameOrBefore(endDate, "day")
    );
  }

  function deleteLine() {
    // get the selected annotation
    var selectedAnnotation = currenChart.annotations().getSelectedAnnotation();
    // remove the selected annotation
    currenChart.annotations().removeAnnotation(selectedAnnotation);
  }

  const getHistorylist = (headerData) => {
    const formData = new URLSearchParams();
    formData.append(
      "exchange",
      headerData?.exch ||
        headerData?.exch_seg ||
        headerData?.Exch ||
        headerData?.exchange_type ||
        "MCX"
    );
    formData.append("symboltoken", headerData?.token);
    formData.append("interval", chartDuration[chartView]?.interval);
    formData.append(
      "fromdate",
      Index.moment()
        .subtract(chartDuration[chartView]?.timeFrame, "d")
        .set({ hour: 9, minute: 15 })
        .format("YYYY-MM-DD HH:mm")
    );
    formData.append(
      "todate",
      Index.moment()
        .add(1, "d")
        .set({ hour: 3, minute: 30 })
        .format("YYYY-MM-DD HH:mm")
    );
    Index.DataService(token)
      .post(Index.Api.user.angelGetAllHistory, formData)
      .then(async (res) => {
        if (res.data.status === 200) {
          let tagDataObj = allTagsData?.find(
            (ele) => ele?.token == headerData?.token
          );

          if (tagDataObj) {
            tagDataObj.stockData = res.data.data;
            setScriptData((prevScriptData) => ({
              ...prevScriptData,
              [headerData?.token]: tagDataObj,
            }));
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (allTagsData?.length > 0) {
      allTagsData?.forEach((tag, index) => {
        setTimeout(() => {
          getHistorylist(tag);
        }, index * 2500);
      });
    }
  }, [allTagsData]);

  useEffect(() => {
    setPaginationModel({
      page: 0,
      pageSize: rowsPerPage,
    });
  }, [rowsPerPage]);

  return (
    <>
      <Index.Box className="dashboard-wrapper open-order-list-wrap">
        <Index.Box
          className={`main-content-sec ${
            displaySettings?.UtilityBar == "left"
              ? "main-content-sec-right"
              : ""
          } ${twoSideArrowZoom && "hide-main-content-margin"}`}
        >
          <Index.Box className={"open-order-body active"}>
            <Index.Box className="report-filter-flex-box">
              <Index.Select
                className="common-dropdown"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="StockSelect"
                // label={"Chart"}
                defaultValue={stockType || "All"}
                value={stockType || "All"}
                // onChange={filterRowData}
                onChange={(e) => {
                  let value = e?.target?.value;
                  setStockType(value);
                }}
              >
                {allStockTypes.map((tag, index) => (
                  <Index.MenuItem
                    key={tag?.id}
                    value={tag?.value}
                    className="common-dropdown-menu"
                  >
                    {tag?.name}
                  </Index.MenuItem>
                ))}
              </Index.Select>

              <Index.Select
                className="common-dropdown"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="tagSelect"
                // label={"Chart"}
                defaultValue={selectedTag || "All"}
                value={selectedTag || "All"}
                // onChange={filterRowData}
                onChange={(e) => {
                  let value = e?.target?.value;
                  setSelectedTag(value);
                }}
              >
                {tags.map((tag, index) => (
                  <Index.MenuItem
                    key={tag?.id}
                    value={tag?.value}
                    className="common-dropdown-menu"
                  >
                    {tag?.name}{" "}
                  </Index.MenuItem>
                ))}
              </Index.Select>

              <Index.Select
                className="common-dropdown"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="tagSelect"
                // label={"Chart"}
                defaultValue={tradeSummaryTag || "All"}
                value={tradeSummaryTag || "All"}
                // onChange={filterRowData}
                onChange={(e) => {
                  let value = e?.target?.value;
                  setTradeSummaryTag(value);
                }}
              >
                {tradeSummaryTags?.map((tag, index) => (
                  <Index.MenuItem
                    key={tag?.id}
                    value={tag?.value}
                    className="common-dropdown-menu"
                  >
                    {tag?.name}{" "}
                  </Index.MenuItem>
                ))}
              </Index.Select>

              <Index.Box className="report-date-range-box">
                <Index.Box onClick={changeShowCalendar}>
                  <Index.TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    className="form-control"
                    placeholder="Select Dates"
                    // onFocus={changeShowCalendar}
                    value={
                      Index.moment(dateRange.startDate).format("DD-MM-YYYY") +
                      " - " +
                      Index.moment(dateRange.endDate).format("DD-MM-YYYY")
                    }
                    onBlur={(e) => {
                      dispatch(activeShortCut(true));
                    }}
                    onFocus={(e) => {
                      dispatch(activeShortCut(false));
                    }}
                  />
                </Index.Box>
                <Index.Box
                  className={`report-date-range-calender-box ${
                    !showCalendar && "date-display-none"
                  } `}
                >
                  <Index.DateRangePicker
                    onChange={handleDateSelect}
                    ranges={[dateRange]}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="table-sec">
            {!loader ? (
              filterdData && filterdData.length > 0 ? (
              <Index.Box sx={{ width: "100%" }}>
                <Index.DataGrid
                  rows={filterdData}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[1000]}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  // checkboxSelection

                  disableRowSelectionOnClick
                />
              </Index.Box>
            ) : (
                <Index.Box
                  sx={{ textAlign: "center", padding: "20px" }}
                  className="text-data"
                >
                  No data found
                </Index.Box>
              )
            ) : (
              <Index.TableLoader />
            )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {miniChartOpen && (
        <>
          {" "}
          <Index.Box>
            <Index.Modal
              open={miniChartOpen}
              onClose={() => {
                handleClose();
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Index.Box sx={style}>
                <Index.Box className="modal-header-sec">
                  <Index.ChartHeader
                    index={JSON.parse(activeMiniChartOpen)}
                    tags={tag}
                    setTags={setTag}
                    allTags={allTags}
                    headerData={{
                      stock_name: wishListData?.filter(
                        (data) => data?.token == JSON.parse(activeMiniChartOpen)
                      )?.[0]?.name,
                      // stockData:socketData[JSON.parse(activeMiniChartOpen)]
                    }}
                    chartData={chartData}
                    onClose={() => {
                      handleClose();
                    }}
                  ></Index.ChartHeader>
                </Index.Box>
                <Index.Box className="modal-body-sec">
                  <Index.Box className="chart-body">
                    <CustomLineChartSingle
                      height={500}
                      width={1000}
                      liveData={socketData}
                      index={activeMiniChartOpen}
                      stockToken={activeMiniChartOpen}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Modal>
          </Index.Box>{" "}
        </>
      )}
    </>
  );
};

export default Report;
