import React, { memo, useEffect, useState } from "react";
import AnyChart from "anychart-react";
import anychart from "anychart";
import Index from "../../Index";
import AutoWidthHeight from "../../common/AutoWidthHeight";
import moment from "moment";
import {
  manageChartDrawingData,
  manageChartInstance,
  manageDrawingChartToken,
} from "../../../redux/user/action";
import { useDispatch } from "react-redux";
import DrawChartButton from "../../modal/DrawChartButton";
import { DataService } from "../../../config/DataService";
import useCurrentInterval from "../../common/current-interval/useCurrentInterval";
import Constants from "../../common/constants";

const chartInterval = {
  ONE_MINUTE: 1,
  FIVE_MINUTE: 5,
  TEN_MINUTE: 10,
  FIFTEEN_MINUTE: 15,
  THIRTY_MINUTE: 30,
  ONE_HOUR: 60,
};

var dataTableStick = {};
const CustomCandlestickChart = (props) => {
  const {
    index,
    DivideHeight,
    shareData,
    borderRadiusClass,
    setCurrenChart,
    setDrawingChart,
    activeChart,
    chartLiveData,
    isMCXMarket,
    openDrawingChartModal,
    handleUpdateAnnotation,
  } = props;
  const {
    token: userToken,
    angelListData,
    chartView,
    otherSettings,
    chartDrawingData,
    displaySettings,
    chartDuration,
    gridSpacing,
    gridBorder,
    isFlip,
    graphType,
    twoSideArrowZoom,
    drawingStockToken,
    theme,
  } = Index.useSelector((state) => state.UserReducer);
  
  const themeTitle = localStorage.getItem("default-theme");
    const candleStickGraph = Index.useSelector(
      (store) => store.UserReducer.GraphStyle[theme].candleStickGraph
    );
  const dispatch = useDispatch();

  const [areaChart, setAreaChart] = React.useState(null);
  const [date, setDate] = React.useState(moment().format("DD-MM-YYYY hh:mm A"));
  const [open, setOpen] = React.useState(0);
  const [high, setHigh] = React.useState(0);
  const [low, setLow] = React.useState(0);
  const [close, setClose] = React.useState(0);
  const [data1, setData1] = useState([]);
  const { height } = AutoWidthHeight();
  const [loading, setLoading] = useState(true);
  const [chartHeight, setChartHeight] = useState(null);

  const [data, setData] = React.useState([]);
  const [newChart, setNewChart] = useState(null);
  const [parentWidth, setParentWidth] = useState(null);
  const [currentInterval, nextInterval] = useCurrentInterval(
    +chartInterval[chartDuration[chartView]?.interval],
    chartLiveData,
    isMCXMarket
  );

  React.useEffect(() => {
    // const chart = charts();
    props?.setCurrenChart && setCurrenChart(charts());
  }, [
    chartDuration,
    candleStickGraph,
    data,
    DivideHeight,
    index,
    otherSettings?.[theme]?.crosshair?.color,
    otherSettings?.[theme]?.crosshair?.thickness,
    otherSettings?.[theme]?.crosshair?.lineType,
    otherSettings?.[theme]?.daySplitter,
    otherSettings?.[theme]?.scale,
    displaySettings?.TimeScale,
    displaySettings?.PriceScale,
    displaySettings?.PriceIndicator,
    displaySettings?.DaySplitter,
    document.getElementsByClassName("chart-header-main")?.[0]?.clientHeight,
    gridSpacing,
    gridBorder,
    isFlip,
    activeChart,
    graphType[theme][chartView],
    loading,
    twoSideArrowZoom,
  ]);

  useEffect(()=>{
    if(!openDrawingChartModal && drawingStockToken == index){
      charts()
      dispatch(manageDrawingChartToken(null))
    }
  },[openDrawingChartModal])

  // React.useEffect(() => {
  //   setData(shareData);
  // }, [angelListData, shareData]);

  useEffect(() => {
    // if (chartView === "Positional") {
    setData(shareData);
    // }
  }, [angelListData, shareData]);

  useEffect(() => {
    if (chartView === "Intraday") {
      addPoint();
    }
  }, [chartLiveData?.tick]);

  useEffect(() => {
    if (shareData?.length > 0) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      setLoading(true);
    }
  }, [shareData]);

  const addPoint = () => {
    // append row of random values to data set
    if (shareData?.length > 0 && chartLiveData?.tick) {
      dataTableStick[index].addData(shareData);
      let chartData = data;
      const liveTimestamp = Index.moment(chartLiveData.tick[0])
        .subtract(5, "hours")
        .subtract(30, "minutes")
        .startOf("minute")
        .valueOf();

      if (liveTimestamp >= currentInterval && liveTimestamp < nextInterval) {
        data.pop();
        data.push([
          Index.moment(currentInterval)
            .add(5, "hours")
            .add(30, "minutes")
            .valueOf(),
          Index.moment(chartLiveData.timestamp).valueOf() === currentInterval
            ? chartLiveData.tick[4]
            : chartLiveData.tick[1],
          chartLiveData.tick[2],
          chartLiveData.tick[3],
          +chartLiveData.tick[4]?.toFixed(2),
          chartLiveData.tick[4],
        ]);
      } else {
        data?.push(chartLiveData.tick);
      }
      setData(chartData);
    }
  };

  const charts = () => {
    const container = document.getElementById(`candlestick_chart${index}`);
    container?.firstElementChild?.remove();

    dataTableStick[index] = anychart.data.table();
    dataTableStick[index].addData(data);
    var mapping = dataTableStick[index].mapAs({
      open: 1,
      high: 2,
      low: 3,
      close: 4,
      value: chartView === "Positional" ? 4 : 5,
    });

    // create stock chart
    var chart = anychart.stock();
    chart.top(13);
    chart.left(-1);
    chart.right(displaySettings?.PriceScale === "on" ? 60 : 0);
    var plot = chart.plot(0);
    var tooltip = chart.tooltip();
    tooltip.width(0);
    tooltip.height(0);
    tooltip.separator(false);
    // create and setup ohlc series on the first plot
    // plot.pointWidth(20);
    var ohlcSeries = chart.plot(0).candlestick(mapping);
    ohlcSeries.risingFill(candleStickGraph.risingColor);
    ohlcSeries.fallingFill(candleStickGraph.fallingColor);
    ohlcSeries.risingStroke(
      candleStickGraph.risingColor,
      candleStickGraph.thickness
    );
    ohlcSeries.fallingStroke(
      candleStickGraph.fallingColor,
      candleStickGraph.thickness
    );

    plot.yScale().inverted(isFlip);

    var extraYAxis = plot.yAxis();
    extraYAxis.orientation("right");

    chart
      .plot(0)
      .xAxis()
      .enabled(displaySettings?.TimeScale === "on");

    if (props?.live) {
      plot
        .xAxis()
        .labels()
        .format((e) => {
          // console.log(e,"fasdfsadfsadf")
          return "";
        });
    }

    chart.plot(0).yAxis().enabled(true);
    chart
      .plot(0)
      .yAxis()
      .stroke(
        otherSettings?.[theme]?.scale?.color,
        otherSettings?.[theme]?.scale?.thickness,
        otherSettings?.[theme]?.scale?.lineType
      );
    chart
      .plot(0)
      .yAxis(0)
      .ticks()
      .stroke(otherSettings?.[theme]?.scale?.color, otherSettings?.[theme]?.scale?.thickness);
    ohlcSeries.name("CSCO");
    chart.plot(0).legend().enabled(false);

    theme == "Light"
      ? plot.xAxis().background("#fff")
      : theme == "Dark"
      ? plot.xAxis().background("#212121")
      : plot.xAxis().background("#283142");

    // Enable mouse wheel zoom.;
    var interactivity = chart.interactivity();
    interactivity.zoomOnMouseWheel(true);

    // var extraYAxis = chart.plot(0).yAxis();
    // extraYAxis
    //   .labels()
    //   .position("right")
    //   .format(function () {
    //     if (this.value) {
    //       return this.value + " ddsd";
    //     }
    //   });
    // extraYAxis.orientation("right");

    // let indicator = chart.plot(0).priceIndicator({ value: "last-visible" });
    // // set line settings
    // chart.plot(0).priceIndicator().stroke("white", 1, "6 4");
    // // configure label
    // chart.plot(0).priceIndicator().label().background().fill("White");
    // chart.plot(0).priceIndicator().label().fontColor("Black");
    if (props?.live && displaySettings?.PriceIndicator === "on") {
      var indicator1 = chart.plot(0).priceIndicator();
      indicator1.value("last-visible");
      indicator1.stroke("white", 1, "6 4");
      // indicator1.fallingStroke('#EF9A9A',1, "6 4");
      indicator1.fallingLabel({ background: candleStickGraph.fallingColor });
      // indicator1.risingStroke('#4CAF50',1, "6 4");
      indicator1.risingLabel({ background: candleStickGraph.risingColor });
    }

    // create a line series
    var crosshair = chart.crosshair();
    crosshair.xLabel().format((e) => {
      const date = Index.moment(e.tickValue)
        .subtract(5, "hours")
        .subtract(30, "minutes");
      return date.hour() === 0 && date.minute() === 0
        ? date.format("DD MMM 'YY")
        : date.format("ddd DD MMM 'YY   HH:mm");
      // return anychart.format.dateTime(e["tickValue"], "YYYY MMM dd HH:mm");
    });

    crosshair.yLabel().format(function (e) {
      return this?.tickValue ? Number(this?.tickValue?.toFixed(2)) : 0
    });

    crosshair.enabled(true);
    // crosshair.displayMode("float");
    if (theme == "Light") {
      crosshair.xStroke(
        otherSettings?.[theme]?.crosshair?.color || "#212121",
        otherSettings?.[theme]?.crosshair?.thickness || 1,
        otherSettings?.[theme]?.crosshair?.lineType || "4 3",
        "round"
      );
      crosshair.yStroke(
        otherSettings?.[theme]?.crosshair?.color || "#212121",
        otherSettings?.[theme]?.crosshair?.thickness || 1,
        otherSettings?.[theme]?.crosshair?.lineType || "4 3",
        "round"
      );
    } else {
      crosshair.xStroke(
        otherSettings?.[theme]?.crosshair?.color || "#ffffff",
        otherSettings?.[theme]?.crosshair?.thickness || 1,
        otherSettings?.[theme]?.crosshair?.lineType || "4 3",
        "round"
      );
      crosshair.yStroke(
        otherSettings?.[theme]?.crosshair?.color || "#ffffff",
        otherSettings?.[theme]?.crosshair?.thickness || 1,
        otherSettings?.[theme]?.crosshair?.lineType || "4 3",
        "round"
      );
    }
    // crosshair.xLabel().enabled(true);
    // crosshair.yLabel().enabled(!props?.live);

    // create scroller series
    chart.scroller().enabled(false);

    // set chart background
    chart.background().fill({
      src: "",
      mode: "fit",
    });
    var background = chart.background();
    background.cornerType("round");
    background.fill(
      theme == "Light"
        ? "#fff"
        : theme == "Dark"
        ? "#212121"
        : "#283142"
    );
    plot.xGrid().enabled(false);
    plot.xGrid().stroke("#ffffff05");
    plot.yGrid().stroke("#ffffff05");
    // background.fill(["var(--chart-background)"])
    // theme=="Light"?background.fill(["#fff"]):theme=="Dark"?background.fill(["#000000"]):background.fill(["#283142"]);
    theme == "Light"
      ? plot.xGrid().palette(["#fff"])
      : theme == "Dark"
      ? plot.xGrid().palette(["#212121"])
      : plot.xGrid().palette(["#283142"]);
    plot.yGrid().enabled(false);
    theme == "Light"
      ? plot.xGrid().palette(["#fff"])
      : theme == "Dark"
      ? plot.xGrid().palette(["#212121"])
      : plot.xGrid().palette(["#283142"]);
    // set chart padding
    chart.padding([0, 0, 0, 0]);

    let mainGridHeight = document.getElementsByClassName(
      "dashboard-chart-main"
    )?.[0]?.clientHeight;
    let singleStockMainHeight =
      document.getElementsByClassName("single-stock-view")?.[0]?.clientHeight;
    let chartHeaderHeight =
      document.getElementsByClassName("chart-header-main")?.[0]?.clientHeight;

    setChartHeight(
      (mainGridHeight -
        chartHeaderHeight * DivideHeight -
        (gridSpacing * 8 * (DivideHeight - 1) + 8) -
        (activeChart ? parseInt(gridBorder) * 4 : 0)) /
        DivideHeight
    );

    var stage = anychart.graphics.create(
      `candlestick_chart${index}`,
      "100%",
      singleStockMainHeight
        ? singleStockMainHeight - chartHeaderHeight - 50
        : (mainGridHeight -
            chartHeaderHeight * DivideHeight -
            (gridSpacing * 8 * (DivideHeight - 1) + 8) -
            (activeChart ? parseInt(gridBorder) * 4 : 0)) /
            DivideHeight
    );

    let chartBodySize =
      document.getElementsByClassName("chart-body")?.[0]?.clientWidth;

    setParentWidth(chartBodySize);

    // var stage = anychart.graphics.create(
    //   `candlestick_chart${index}`,
    //   "100%",
    //   (height - 242) / DivideHeight
    // );
    chart.tooltip().titleFormat(function () {
      // save hovered date to variable
      setDate(moment(this.hoveredDate).format("DD-MM-YYYY hh:mm A"));
      setOpen(this.formattedValues[0].split("  ")[1].split(" ")[1]);
      setHigh(this.formattedValues[0].split("  ")[2].split(" ")[1]);
      setLow(this.formattedValues[0].split("  ")[3].split(" ")[1]);
      setClose(this.formattedValues[0].split("  ")[4].split(" ")[1]);
    });

    chart.listen("click", function (e) {
      props?.setDrawingChart && setDrawingChart({ chart, token: props?.token });
    });
    
    if(activeChart){
      props?.setDrawingChart && setDrawingChart({ chart, token: props?.token });
    }

    if (!loading) {
      chart.container(stage).draw();
      setNewChart(chart);
      drawAnnotationsWithData(chart);
      drawingAnnotationLogic(chart);
      handleDrawDaySplitter(plot, shareData);
      return chart;
    }
  };

  const handleDrawDaySplitter = (plot, stockData) => {
    if (
      chartView === "Positional" &&
      shareData?.length &&
      displaySettings?.DaySplitter === "on"
    ) {
      const groupedData = stockData.reduce((acc, curr) => {
        const date = Index.moment(curr[0]).startOf("day").valueOf();
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(curr[0]);
        return acc;
      }, {});

      const filteredData = Object.values(groupedData).map(
        (group) => group[group.length - 1]
      );

      filteredData?.forEach((item, index) => {
        var marker = plot.lineMarker(index);
        marker.axis(plot.xAxis());
        marker.value(item);
        marker.stroke({
          thickness: otherSettings?.[theme]?.daySplitter?.thickness,
          color: otherSettings?.[theme]?.daySplitter?.color,
          dash: otherSettings?.[theme]?.daySplitter?.lineType,
        });
      });
    }
  };

  const drawingAnnotationLogic = (chart) => {
    if (chart) {
      //<<<<<<<<<<<<<<<<<<< Annotation Unselect >>>>>>>>>>>>>>>>>
      chart.listen("annotationSelect", function (event) {
        let selectedAnnotation = event?.annotation;

        // chart.title(
        //   "The " + selectedAnnotation.getType() + " annotation is select."
        // );
      });

      // //<<<<<<<<<<<<<<<<<<< Annotation Unselect >>>>>>>>>>>>>>>>>
      // chart.listen("annotationUnselect", function (event) {
      //   var unselectAnnotation = event.annotation;

      // });

      //<<<<<<<<<<<<<<<<<<< Annotation Change >>>>>>>>>>>>>>>>>
      chart.listen("annotationChange", function (event) {
        let changeAnnotation = event.annotation;
      });

      //<<<<<<<<<<<<<<<<<<< Annotation Change Start >>>>>>>>>>>>>>>>>
      chart.listen("annotationChangeStart", function (event) {
        let changeStartAnnotation = event.annotation;
      });

      // //<<<<<<<<<<<<<<<<<<< Annotation Change Finish >>>>>>>>>>>>>>>>>
      chart.listen("annotationChangeFinish", function (event) {
        let finishAnnotationChange = event.annotation;

        let myData = chart.plot(0).annotations().toJson();
        let dataToStore = myData?.annotationsList;

        if (props?.token) {
          let annotationData = {
            token: +props?.token,
            data: dataToStore,
            chartType: chartView,
            interval: Constants.annotationTimeFrames[chartDuration[chartView]?.interval],
          };

          handleUpdateAnnotation(annotationData);
        }
      });

      // //<<<<<<<<<<<<<<<<<<< Annotation Drawing Finish >>>>>>>>>>>>>>>>>
      chart.listen("annotationDrawingFinish", function (event) {
        let finishAnnotationDrawing = event.annotation;

        let myData = chart.plot(0).annotations().toJson();
        let dataToStore = myData?.annotationsList;
        if (props?.token) {
          let annotationData = {
            token: +props?.token,
            data: dataToStore,
            chartType: chartView,
            interval: Constants.annotationTimeFrames[chartDuration[chartView]?.interval],
          };

          handleUpdateAnnotation(annotationData);
        }
      });
    }
  };

  //Draw saved data
  function drawAnnotationsWithData(chart) {
    if (chart) {
      let savedData;

      if (chartDrawingData?.length > 0) {
        savedData = chartDrawingData?.find(
          (item) => item.token == +props?.token
        );
      }

      const controller = chart.plot(0).annotations();

      if (
        savedData &&
        savedData?.data[Constants.annotationTimeFrames[chartDuration[chartView]?.interval]]
          ?.length > 0
      ) {
        savedData?.data[
          Constants.annotationTimeFrames[chartDuration[chartView]?.interval]
        ]?.forEach((annotationData) => {
          if (annotationData?.type == "ellipse") {
            controller.ellipse(annotationData);
          }
          if (annotationData?.type == "triangle") {
            controller.triangle(annotationData);
          }
          if (annotationData?.type == "trend-channel") {
            controller.trendChannel(annotationData);
          }
          if (annotationData?.type == "line") {
            controller.line(annotationData);
          }
          if (annotationData?.type == "andrews-pitchfork") {
            controller.andrewsPitchfork(annotationData);
          }
          if (annotationData?.type == "horizontal-line") {
            controller.horizontalLine(annotationData);
          }
          if (annotationData?.type == "fibonacci-fan") {
            controller.fibonacciFan(annotationData);
          }
          if (annotationData?.type == "vertical-line") {
            controller.verticalLine(annotationData);
          }
          if (annotationData?.type == "fibonacci-arc") {
            controller.fibonacciArc(annotationData);
          }
          if (annotationData?.type == "infinite-line") {
            controller.infiniteLine(annotationData);
          }
          if (annotationData?.type == "fibonacci-retracement") {
            controller.fibonacciRetracement(annotationData);
          }
          if (annotationData?.type == "ray") {
            controller.ray(annotationData);
          }
          if (annotationData?.type == "fibonacci-timezones") {
            controller.fibonacciTimezones(annotationData);
          }
          if (annotationData?.type == "marker") {
            controller.marker(annotationData);
          }
          if (annotationData?.type == "rectangle") {
            controller.rectangle(annotationData);
          }
        });
      }
    }
  }

  return (
    <>
      <Index.Box className="custom_candlestick_chart">
        {displaySettings?.OHLCValues === "on" && (
          <Index.Stack direction="row" className="chart_ohcl">
            <Index.Typography
              sx={{ fontSize: parentWidth * 0.03 }}
              variant="caption"
              color="#5A76B4"
            >
              O : {(+open)?.toFixed(2)?.toString().trim()}; &nbsp;
            </Index.Typography>
            <Index.Typography
              sx={{ fontSize: parentWidth * 0.03 }}
              variant="caption"
              color="#00FF00"
            >
              H : {(+high)?.toFixed(2)?.toString().trim()}; &nbsp;
            </Index.Typography>
            <Index.Typography
              sx={{ fontSize: parentWidth * 0.03 }}
              variant="caption"
              color="#FF0000"
            >
              L : {(+low)?.toFixed(2)?.toString().trim()}; &nbsp;
            </Index.Typography>
            <Index.Typography
              sx={{ fontSize: parentWidth * 0.03 }}
              variant="caption"
              color="#5A76B4"
            >
              C : {(+close)?.toFixed(2)?.toString().trim()};
            </Index.Typography>
          </Index.Stack>
        )}
        {/* <Index.Stack direction="row" className="chart_ohcl chart_date">
          <Index.Typography variant="caption" color="#42506F">
            Date : {date};
          </Index.Typography>
        </Index.Stack> */}
        {loading ? (
          <Index.Skeleton
            animation="wave"
            sx={{ bgcolor: "grey.1000" }}
            variant="rectangular"
            width={"100%"}
            height={chartHeight}
          />
        ) : (
          <div
            id={`candlestick_chart${index}`}
            className={`${borderRadiusClass}`}
          />
        )}
      </Index.Box>
    </>
  );
};

export default memo(CustomCandlestickChart);
